import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allProgramAuditLogs } from "../../modules/audit";
import { Table, Card } from "antd/lib";
import {
  programAuditColumns,
  // filterByDate,
  sort,
  // filterText,
  filterSearchResult,
  // filterAuditLogs,
} from "../../helpers/helpers.js";
import {
  DatePicker,
  Collapse,
  Form,
  Input,
  Select,
  message,
  Button,
  Row,
  Col,
} from "antd/lib";
import { CaretRightOutlined, DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Option } = Select;

const ProgramAudit = () => {
  const dispatch = useDispatch();
  const { audit } = useSelector((state) => state);
  const [auditList, setAuditList] = useState();
  useEffect(() => {
    dispatch(allProgramAuditLogs());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAuditList(audit);
  }, [audit]); // eslint-disable-line react-hooks/exhaustive-deps

  const onFilterSubmit = (values) => {
    var filterObject = {};

    if (values.Date !== null) {
      filterObject.StartDate =
        values.Date[0].format("YYYY-MM-DD") + " 00:00:00";
      filterObject.EndDate = values.Date[1].format("YYYY-MM-DD") + " 23:59:59";
    }
    if (values.User !== undefined) {
      filterObject.User = values.User;
    }
    if (values.Action !== undefined) {
      filterObject.Action = values.Action;
    }
    if (values.OldValue !== undefined) {
      filterObject.OldValue = values.OldValue;
    }
    if (values.NewValue !== undefined) {
      filterObject.NewValue = values.NewValue;
    }
    let searchResult = filterSearchResult(audit, filterObject);
    setAuditList(searchResult);
  };

  const formatString = (value) => {
    let jsonObject =
      value === undefined || value === null ? null : JSON.parse(value);
    let text = "";
    if (jsonObject) {
      for (let prop in jsonObject) {
        if (prop === "IsNational") {
          text +=
            "National : " + (jsonObject[prop] === "1" ? "Yes" : "No") + "\r\n";
        } else if (prop === "IsProgramActive") {
          text +=
            "Status : " + (jsonObject[prop] === "1" ? "Yes" : "No") + "\r\n";
        } else {
          text += prop + " : " + jsonObject[prop] + "\r\n";
        }
      }
    }
    return text.substr(0, text.length - 1);
  };

  const onDownload = (e) => {
    var result = auditList.map(({ OldValue, NewValue, ...rest }) => ({
      ...rest,
      OldValue: formatString(OldValue),
      NewValue: formatString(NewValue),
    }));
    const ws = XLSX.utils.json_to_sheet(result);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "AuditLogs");
    XLSX.writeFile(wb, `ProgramAudit.xlsx`);
    if (auditList.length !== audit.length)
      message.success("Filtered Program Audit Logs downloaded!");
    else {
      message.success("All Program Audit Logs downloaded!");
    }
  };
  const onReset = (e) => {
    form.resetFields();
    setAuditList(audit);
  };

  const [form] = Form.useForm();

  return (
    <div className="row">
      <Card title="PROGRAM AUDIT">
        <Collapse
          bordered={false}
          ghost
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel header="FILTER" key="1">
            <Form
              form={form}
              name="formAuditFilter"
              onFinish={onFilterSubmit}
              labelAlign="right"
            >
              <Row>
                <Col>
                  <Form.Item
                    name="Date"
                    label="Date"
                    style={{ marginLeft: "2em" }}
                  >
                    <RangePicker
                      className="audit-rangepicker"
                      aria-label="Filter"
                      id="rangePicker"
                      title="Filter"
                      // onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="User"
                    label="User"
                    style={{ marginLeft: "2em" }}
                  >
                    <Select
                      // mode="single"
                      allowClear
                      style={{ width: "15em" }}
                      // onChange={handleUserChange}
                    >
                      {auditList
                        ? sort([...new Set(audit.map((x) => x.User))]).map(
                            (value) => {
                              return (
                                <Option value={value} key={value} label={value}>
                                  {value}
                                </Option>
                              );
                            }
                          )
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="Action"
                    label="Action"
                    style={{ marginLeft: "2em" }}
                  >
                    <Select
                      //  mode="single"
                      allowClear
                      style={{ width: "7em" }}
                    >
                      <Option value="ADD" key="ADD" label="ADD">
                        ADD
                      </Option>
                      <Option value="EDIT" key="EDIT" label="EDIT">
                        EDIT
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Item
                    name="OldValue"
                    label="Old Value"
                    style={{ marginLeft: "2em" }}
                  >
                    <Input
                      placeholder="Enter keyword"
                      style={{ width: "20em" }}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="NewValue"
                    label="New Value"
                    style={{ marginLeft: "2em" }}
                  >
                    <Input
                      placeholder="Enter keyword"
                      style={{ width: "20em" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Button
                  type="danger"
                  htmlType="submit"
                  style={{ width: "10em", float: "right", marginLeft: "20px" }}
                >
                  Filter
                </Button>
                <Button
                  type="danger"
                  style={{ width: "10em", float: "right", marginLeft: "20px" }}
                  onClick={onReset}
                >
                  Reset Filter
                </Button>
                <Button
                  type="danger"
                  style={{ width: "10em", float: "right", marginLeft: "20px" }}
                  onClick={onDownload}
                  icon={<DownloadOutlined />}
                >
                  Download
                </Button>
              </Row>
            </Form>
          </Panel>
        </Collapse>

        <Table
          className="program-table"
          rowClassName="audit-rows"
          columns={programAuditColumns}
          dataSource={auditList}
          scroll={{ x: "1000" }}
          pagination={{ pageSize: 10 }}
        />
      </Card>
    </div>
  );
};

export default ProgramAudit;
