import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allLuckyCodes, deleteLuckyCode } from "../../modules/luckycodes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { requiredTypes } from "../../helpers/helpers";
import { allPrograms } from "../../modules/programs";
import LuckyCodeModal from "./LuckyCodeModal";
import {
  Table,
  Card,
  Button,
  message,
  Row,
  Col,
} from "antd/lib";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

import * as XLSX from "xlsx";
import '../../styles/LuckyCode.css';

const LuckyCodeList = () => {
  let dispatch = useDispatch();
  const { luckycodes } = useSelector((state) => state);
  const { programs } = useSelector((state) => state)
  const [programList, setProgramList] = useState();
  const [luckyCodesList, setLuckyCodesList] = useState();
  const [luckyCode, setLuckyCode] = useState();
  const [visible, setVisible] = useState(false);
  const [action, setAction] = useState();

  useEffect(() => {
    dispatch(allLuckyCodes());
    dispatch(allPrograms());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setProgramList(programs);
  }, [programs]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setLuckyCodesList(luckycodes);
  }, [luckycodes]); // eslint-disable-line react-hooks/exhaustive-deps

  const openModal = (action) => {
    showModal();
    setAction(action);
  };
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const luckyCodeColumns = [
    {
      title: "EDIT",
      key: "operation",
      width: 30,
      fixed: "left",
      render: (text, record, index) => (

        <a
          onClick={(e) => {
            record.Date = [moment.utc(record.StartDate), moment.utc(record.EndDate)];
            setLuckyCode(record);
            openModal("Edit");
          }}
        >
          <FontAwesomeIcon icon={faEdit} />

        </a>

      ),
    },
    {
      title: "ID",
      width: 50,
      dataIndex: "ID",
      key: "ID",
      name: "ID",
      maxLength: 3,
    },
    {
      title: "TITLE",
      width: 200,
      dataIndex: "Title",
      key: "Title",
      name: "Title",
      maxLength: 30,
      required: requiredTypes.default,
    },
    {
      title: "START DATE",
      width: 120,
      dataIndex: "StartDate",
      key: "StartDate",
      name: "Start Date",
      required: requiredTypes.default,
      render: (value) => moment.utc(value).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      title: "END DATE",
      width: 120,
      dataIndex: "EndDate",
      key: "EndDate",
      name: "End Date",
      required: requiredTypes.default,
      render: (value) => moment.utc(value).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      title: "WIN COUNT",
      width: 200,
      dataIndex: "UserWinCount",
      key: "UserWinCount",
      name: "UserWinCount"
    },
    {
      title: "WIN TYPE",
      width: 200,
      dataIndex: "WinType",
      key: "WinType",
      name: "WinType"
    },
  ];

  const combinationColumns = [
    {
      title: 'Level',
      dataIndex: 'Level',
      key: 'Level',
      width: 10,
    },
    {
      title: 'RewardCount',
      dataIndex: 'RewardCount',
      key: 'RewardCount',
      width: 10,
    },
    {
      title: 'RewardPoints',
      dataIndex: 'RewardPoints',
      key: 'RewardPoints',
      width: 10,
    },

  ];


  const softDeleteLuckyCode = (luckyCode) => {
    let confirm = window.confirm("Are you sure you want to delete " + luckyCode.Title + "?");

    if (confirm === true) {
        dispatch(deleteLuckyCode(luckyCode.ID))
                    .then((response) => {
                        let data = response.payload.data;
                        if (data.success) {
                            message.success(`Lucky Code deleted successfully!`, 5);
                            dispatch(allLuckyCodes());
                        } else {
                            message.error("Error on deleting lucky code");
                        }
                    })
                    .catch((e) => {
                        message.error("Error on deleting lucky code!");
                    });
    }

  };

  const onDownload = (e) => {
    let exportList = [];
    let list = luckyCodesList;

    list.map(lc => {
      lc.Combinations.map(lcc => {
        exportList.push({
          ...lc,
          StartDate: moment(lc.StartDate).format("YYYY-MM-DD HH:mm:ss"),
          EndDate: moment(lc.EndDate).format("YYYY-MM-DD HH:mm:ss")
          , ...lcc
        })
        delete exportList["Combinations", "LuckyCodeID"];
      })

    });

    const ws = XLSX.utils.json_to_sheet(exportList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "LuckyCode");
    XLSX.writeFile(wb, `LuckyCode.xlsx`);
    message.success("All Lucky Code downloaded!");
  }
  return (
    <div className="row">
      <Card title="LUCKY CODE LIST">
        <Row>
          <Col flex={3}>
            <Button
              className="btnHurdle"
              type="danger"
              style={{
                width: "10em",
                float: "right",
                marginLeft: "20px",
                marginBottom: "20px",
              }}
              onClick={onDownload}
              icon={<DownloadOutlined />}
            >
              Download
            </Button>

            <Button
              className="btnHurdle"
              type="danger"
              icon={<PlusOutlined />}
              style={{
                width: "10em",
                float: "right",
                marginRight: "6px",
                marginBottom: "20px",
              }}
              onClick={(e) => openModal("Add")}
            >
              Add
            </Button>

          </Col>
        </Row>

        <Table
          className="luckycode-table"
          rowClassName="row-luckycode"
          rowKey={(lc) => lc.ID}
          columns={luckyCodeColumns}
          dataSource={luckyCodesList}
          scroll={{ x: "max-content" }}
        />

        <LuckyCodeModal
          luckyCode={action !== "Add" ? luckyCode : null}
          show={showModal}
          programs={programList}
          luckyCodes={luckyCodesList}
          onCancel={handleCancel}
          columns={{ ...luckyCodeColumns, Combinations: combinationColumns }}
          action={action}
          visible={visible}
          style={{ height: 'calc(100vh - 200px)' }}
          bodyStyle={{ overflowY: 'scroll' }}

        />
      </Card>
    </div>
  );
};
export default LuckyCodeList;
