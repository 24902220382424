import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";

import {
    Table,
    Card,
    Col,
    Form,
    Row,
    Button,
    Select,
    Collapse,
    message
} from "antd/lib";

import * as XLSX from "xlsx";
import { sort } from "../../helpers/helpers";

import {
    CaretRightOutlined,
    DownloadOutlined,
    PlusOutlined
} from "@ant-design/icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import '../../styles/Program.css';

import {
    filterSearchResult,
    omnivoucherProgramsColumns,
    fortmatExportList
} from "../../helpers/omnivoucherPrograms";

import { fnGetAllOmnivoucherPrograms } from "../../modules/omnivoucher/omnivoucherPrograms";
import { allTerritories } from "../../modules/territories";

import OmnivoucherProgramModal from "./OmnivoucherProgramModal";

const OmnivoucherProgramsList = () => {

    const dispatch = useDispatch();

    // get omnivoucherPrograms from state
    const { omnivoucherPrograms, territories } = useSelector((state) => state);

    // holder of omnivoucherProgram list
    const [ omnivoucherProgramsList, setOmnivoucherList ] = useState([]);
    const [ salesOfficeList, setSalesOfficeList ] = useState();
    const [ regionList, setRegionList ] = useState();
    const [ territoryList, setTerritoryList ] = useState();

    // holder of specifi omnivoucherProgram
    const [ omnivoucherProgram, setProgram ] = useState();

    const [ visible, setVisible ] = useState(false);
    const [ action, setAction ] = useState();

    useEffect(() => {
        dispatch(fnGetAllOmnivoucherPrograms());
        dispatch(allTerritories());
    }, []);

    useEffect(() => {
        let result = omnivoucherPrograms.map(pr => ({ ...pr }));
        setOmnivoucherList(result);
    }, [ omnivoucherPrograms ]);

    useEffect(() => {
        setRegionList(sort([ ...new Set(territories.map((x) => x.Region)) ]));
        setSalesOfficeList(sort([ ...new Set(territories.map((x) => x.SalesOffice)) ]));
        setTerritoryList(sort([ ...new Set(territories.map((x) => x.TerritoryName)) ]));
    }, [ territories ]);

    const operationColumn = {
        title: "",
        key: "operation",
        width: 40,
        fixed: "left",
        render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
                onClick={(e) => {
                    record.Date = [ moment.utc(record.StartDate), moment.utc(record.EndDate) ];
                    setProgram(record);
                    openModal("Edit");
                }}
            >
                <FontAwesomeIcon icon={faEdit} />
            </a>
        ),
    }

    const openModal = (action) => {
        showModal();
        setAction(action);
    };

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onFilterSubmit = (values) => {

        let filter = {};

        Object.keys(values).map((key) => {
            if (values[ key ] !== undefined) {
                filter[ key ] = values[ key ];
            }
        });

        let searchResult = filterSearchResult(omnivoucherPrograms, filter);

        setOmnivoucherList(searchResult);
    };

    const onReset = (e) => {
        form.resetFields();
        setOmnivoucherList(omnivoucherPrograms);
    };

    const onDownload = (e) => {

        let exportList = fortmatExportList(omnivoucherProgramsList);

        const workSheet = XLSX.utils.json_to_sheet(exportList);
        const workBook = XLSX.utils.book_new();

        XLSX.utils.book_append_sheet(workBook, workSheet, "Omnivouchers");

        XLSX.writeFile(workBook, `Omnivouchers.xlsx`);

        if (omnivoucherProgramsList.length !== omnivoucherPrograms.length)
            message.success("Filtered Omnivouchers downloaded!");
        else {
            message.success("All Omnivouchers downloaded!");
        }
    };

    const [ form ] = Form.useForm();

    const { Panel } = Collapse;
    const { Option } = Select;

    return (
        <div className="row">
            <Card title="OMNIVOUCHER PROGRAM LIST">

                <Button
                    type="danger"
                    style={{ width: "10em", float: "right", marginLeft: "20px" }}
                    onClick={onDownload}
                    icon={<DownloadOutlined />}
                >
                    Download
                </Button>
                <Button
                    className="btnHurdle"
                    type="danger"
                    icon={<PlusOutlined />}
                    style={{
                        width: "10em",
                        float: "right",
                        marginRight: "6px",
                        marginBottom: "20px",
                    }}
                    onClick={(e) => openModal("Add")}
                >
                    Add
                </Button>
                <br />
                <br />
                <Collapse
                    bordered={false}
                    ghost
                    expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                >
                    <Panel header="FILTER" key="1">
                        <Form
                            form={form}
                            name="filter"
                            onFinish={onFilterSubmit}
                            labelAlign="right"
                        >
                            <Row>

                                <Col>
                                    <Form.Item
                                        name="IsActive"
                                        label="Status"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: "7em" }}
                                        >
                                            <Option value={false} key={false} label="No">
                                                No
                                            </Option>
                                            <Option value={true} key={true} label="Yes">
                                                Yes
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="Description"
                                        label="Program Description"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: "10em" }}
                                        >
                                            {omnivoucherProgramsList
                                                ? sort([
                                                    ...new Set(omnivoucherPrograms.map((x) => x.Description)),
                                                ]).map((value) => {
                                                    return (
                                                        <Option value={value} key={value} label={value}>
                                                            {value}
                                                        </Option>
                                                    );
                                                })
                                                : null}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="IsNational"
                                        label="National"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: "7em" }}
                                        >
                                            <Option value={false} key={false} label="No">
                                                No
                                            </Option>
                                            <Option value={true} key={true} label="Yes">
                                                Yes
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="Region"
                                        label="Region"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Separated by comma. Leave BLANK if none"
                                            allowClear
                                            optionLabelProp="label"
                                            style={{ width: "10em" }}
                                        >
                                            {regionList
                                                ? regionList.map((region) => {
                                                    return (
                                                        <Option value={region} key={region} label={region}>
                                                            {region}
                                                        </Option>
                                                    );
                                                })
                                                : null}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="SalesOffice"
                                        label="Sales Office"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Separated by comma. Leave BLANK if none"
                                            allowClear
                                            style={{ width: "10em" }}
                                        >
                                            {salesOfficeList
                                                ? salesOfficeList.map((value) => {
                                                    return (
                                                        <Option value={value} key={value} label={value}>
                                                            {value}
                                                        </Option>
                                                    );
                                                })
                                                : null}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="Territory"
                                        label="Territory"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Separated by comma. Leave BLANK if none"
                                            allowClear
                                            optionLabelProp="label"
                                            style={{ width: "10em" }}
                                        >
                                            {territoryList
                                                ? territoryList.map((territory) => {
                                                    return (
                                                        <Option
                                                            value={territory}
                                                            key={territory}
                                                            label={territory}
                                                        >
                                                            {territory}
                                                        </Option>
                                                    );
                                                })
                                                : null}
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row>
                                <Button
                                    type="danger"
                                    htmlType="submit"
                                    style={{ width: "10em", float: "right", marginLeft: "20px" }}
                                >
                                    Filter
                                </Button>

                                <Button
                                    type="danger"
                                    style={{ width: "10em", float: "right", marginLeft: "20px" }}
                                    onClick={onReset}
                                >
                                    Reset Filter
                                </Button>
                            </Row>

                        </Form>
                    </Panel>
                </Collapse>

                {omnivoucherProgramsList.length > 0 ? (
                    <Table
                        className="omnivoucherProgram-table"
                        rowClassName="row-omnivoucherlist"
                        rowKey={(omnivoucherProgram) => omnivoucherProgram.OmnivoucherID}
                        columns={[ operationColumn, ...omnivoucherProgramsColumns ]}
                        dataSource={omnivoucherProgramsList}
                        scroll={{ x: "max-content" }}
                        pagination={{ pageSize: 15 }}
                    />
                ) : (
                    <Table
                        className="omnivoucherProgram-table"
                        rowClassName="row-omnivoucherlist"
                        columns={[ operationColumn, ...omnivoucherProgramsColumns ]}
                        scroll={{ x: "max-content" }}
                        pagination={{ pageSize: 15 }}
                    />
                )}

                <OmnivoucherProgramModal
                    omnivoucherProgram={action === "Edit" ? omnivoucherProgram : null}
                    show={showModal}
                    omnivoucherPrograms={omnivoucherProgramsList}
                    onCancel={handleCancel}
                    columns={[ operationColumn, ...omnivoucherProgramsColumns ]}
                    action={action}
                    visible={visible}
                    className="modal-hurdle"
                />
            </Card>
        </div>
    );
};

export default OmnivoucherProgramsList;
