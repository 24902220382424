import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Table,
    Card,
    Col,
    Form,
    Row,
    Button,
    Input,
    Select,
    Collapse,
    message
} from "antd/lib";

import * as XLSX from "xlsx";
import { sort } from "../../helpers/helpers";

import { CaretRightOutlined, DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import '../../styles/Program.css';

import {
    omnivoucherColumns,
    fortmatExportList
} from "../../helpers/omnivouchers";

import {
    fnGetAllOmnivouchers,
    fnAddOmnivoucher,
} from "../../modules/omnivoucher/omnivouchers";
import { allTerritories } from "../../modules/territories";

import { fnGetAllOmnivoucherPrograms } from "../../modules/omnivoucher/omnivoucherPrograms";

const importExcel = (file, items) => {

    const fileReader = new FileReader();

    let rows = [];

    fileReader.onload = (event) => {

        try {
            const { result } = event.target;

            const workbook = XLSX.read(result, { type: "binary" });

            for (const Sheet in workbook.Sheets) {

                XLSX.utils.sheet_to_row_object_array(workbook.Sheets[Sheet]);

                if (workbook.Sheets.hasOwnProperty(Sheet)) {

                    let data = XLSX.utils.sheet_to_row_object_array(
                        workbook.Sheets[Sheet]
                    );

                    data.forEach((row) => {
                        if (
                            row["Voucher Code"] &&
                            row["Control Number"] &&
                            row["Status"] &&
                            row["Program Description"]
                        ) {
                            rows.push({
                                VoucherCode: row["Voucher Code"],
                                ControlNumber: row["Control Number"],
                                Status: row["Status"],
                                Description: row["Program Description"],
                            });
                        }
                    });

                    items(rows);
                }
            }

        } catch (e) {

            console.log(e);

            message.error("File Type is incorrect!");
        }
    };

    fileReader.readAsBinaryString(file);
};

const OmnivouchersList = () => {

    const dispatch = useDispatch();

    // get omnivouchers and territories from state
    const { /*omnivouchers,*/ omnivoucherPrograms, territories } = useSelector((state) => state);

    // holder of omnivoucher list
    const [salesOfficeList, setSalesOfficeList] = React.useState();
    const [regionList, setRegionList] = React.useState();
    const [territoryList, setTerritoryList] = React.useState();

    // const [ visible, setVisible ] = React.useState(false);
    // const [ action, setAction ] = React.useState();

    const [omnivouchersList, setOmnivoucherList] = React.useState([]);
    const [omnivoucherListTotal, setOmnivoucherListTotal] = React.useState(0);
    const [omnivoucherListLoading, setOmnivoucherListLoading] = React.useState(true);

    const [filterState, setFiltersState] = React.useState({});

    const [paginationState, setPaginationState] = React.useState({
        currentPage: 1,
        pageSize: 15,
    });

    const [programList, setProgramList] = React.useState([]);

    React.useEffect(() => {
        dispatch(allTerritories());
        dispatch(fnGetAllOmnivoucherPrograms());
    }, [dispatch]);

    React.useEffect(() => {
        dispatch(fnGetAllOmnivouchers(filterState, paginationState))
            .then((response) => {

                if (Object.hasOwnProperty.call(response, 'error')) {

                    if (
                        Object.hasOwnProperty.call(response.error, 'response') &&
                        Object.hasOwnProperty.call(response.error.response, 'data') &&
                        Object.hasOwnProperty.call(response.error.response.data, 'message')
                    ) {
                        message.error(
                            response.error.response.data.message
                        );

                        return;

                    } else {

                        throw response.error;
                    }
                }

                setOmnivoucherList(response.payload.data.omnivouchers);
                setOmnivoucherListTotal(response.payload.data.totalVoucherCount);
                setOmnivoucherListLoading(false);
            })
            .catch((error) => {

                console.log(error);

                message.error("Error on fetching Omnivouchers.");
            });

    }, [dispatch, filterState, paginationState]);

    // React.useEffect(() => {
    //     setOmnivoucherList(omnivouchers);
    //     setOmnivoucherListTotal(omnivouchers.length);
    // }, [omnivouchers]);

    React.useEffect(() => {
        setRegionList(sort([...new Set(territories.map((x) => x.Region))]));

        setSalesOfficeList(
            sort([...new Set(territories.map((x) => x.SalesOffice))])
        );

        setTerritoryList(
            sort([...new Set(territories.map((x) => x.TerritoryName))])
        );
    }, [territories]);

    React.useEffect(() => {
        let result = omnivoucherPrograms.map(pr => ({ ...pr }));
        setProgramList( result );
    }, [omnivoucherPrograms]);

    /*
    const operationColumn = {
        title: "",
        key: "operation",
        width: 40,
        fixed: "left",
        render: (text, record, index) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
                onClick={(e) => {
                    record.Date = [ moment.utc(record.StartDate), moment.utc(record.EndDate) ];
                    setProgram(record);
                    openModal("Edit");
                }}
            >
                <FontAwesomeIcon icon={faEdit} />
            </a>
        ),
    }

    const openModal = (action) => {
        showModal();
        setAction(action);
    };

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
    };
    */

    const onFilterSubmit = (values) => {

        let filters = {};

        Object.keys(values).map((key) => {
            if (values[key] !== undefined) {
                filters[key] = values[key];
            }
        });

        // let searchResult = filterSearchResult(omnivouchersList, filters);
        // setOmnivoucherList(searchResult);

        setOmnivoucherListLoading(true);
        setFiltersState(filters);
    };

    const onResetFilter = (e) => {

        form.resetFields();

        setOmnivoucherListLoading(true);
        setFiltersState({});
    };

    const onDownload = async (e) => {
      try {

          const response = await dispatch(fnGetAllOmnivouchers(filterState, {
            ...paginationState,
            currentPage: 1,
            pageSize: 999999
          }));

          const allOmnivouchers = response.payload.data.omnivouchers;

          const exportList = fortmatExportList(allOmnivouchers);

          const workSheet = XLSX.utils.json_to_sheet(exportList);
          const workBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workBook, workSheet, "Omnivouchers");

          XLSX.writeFile(workBook, `Omnivouchers.xlsx`);

          if (Object.keys(filterState).length !== 0) {
              message.success("Filtered Omnivouchers downloaded!");
          } else {
              message.success("All Omnivouchers downloaded!");
          }
      } catch (error) {
          console.error("Error fetching or exporting omnivouchers:", error);
          message.error("Error downloading Omnivouchers. Please try again.");
      }
  };


    const onImportExcel = (event) => {

        const { files } = event.target;

        if (files.length === 1) {

            importExcel(files[0], (rows) => {

                if (rows.length === 0) {

                    message.error("Upload failed! Omnivoucher columns does not exist in the file", 10);

                    return;
                }

                dispatch(fnAddOmnivoucher(rows))
                    .then((response) => {

                        if (Object.hasOwnProperty.call(response, 'error')) {

                            if (
                                Object.hasOwnProperty.call(response.error, 'response') &&
                                Object.hasOwnProperty.call(response.error.response, 'data') &&
                                Object.hasOwnProperty.call(response.error.response.data, 'message')
                            ) {
                                message.error(
                                    response.error.response.data.message
                                );

                                return;

                            } else {

                                throw response.error;
                            }
                        }

                        let data = response.payload.data;

                        if (data.success) {

                            message.success(
                                `${data.count}/${rows.length} Omnivouchers added successfully!`,
                                10
                            );

                            setOmnivoucherListLoading(true);

                            setFiltersState({});

                            setPaginationState({
                                totalItems: 0,
                                currentPage: 1,
                                pageSize: 15,
                            });

                        } else {

                            if (data.count === 0) {

                                message.error("Upload failed! Omnivoucher already exist!");

                            } else {

                                message.error("Error on uploading Omnivouchers.");
                            }
                        }
                    })
                    .catch((error) => {

                        console.log(error);

                        message.error("Error on uploading Omnivouchers.");
                    });
            });
        }

        event.target.value = null;
    };

    const [form] = Form.useForm();

    const { Panel } = Collapse;
    const { Option } = Select;

    return (
        <div className="row">
            <Card title="OMNIVOUCHER LIST">

                <Button
                    type="danger"
                    style={{ width: "10em", float: "right", marginLeft: "20px" }}
                    onClick={onDownload}
                    icon={<DownloadOutlined />}
                >
                    Download
                </Button>

                <Button
                    className="btnUpload"
                    type="danger"
                    icon={<UploadOutlined />}
                    style={{ width: "9.5em", float: "right", marginRight: "10px" }}
                >
                    <label htmlFor="filePicker" style={{}}>
                        {" "}
                        Bulk Upload
                    </label>
                    <input
                        id="filePicker"
                        style={{ visibility: "hidden" }}
                        type={"file"}
                        onChange={onImportExcel}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    ></input>
                </Button>

                <br />
                <br />
                <Collapse
                    bordered={false}
                    ghost
                    expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                >
                    <Panel header="FILTER" key="1">
                        <Form
                            form={form}
                            name="filter"
                            onFinish={onFilterSubmit}
                            labelAlign="right"
                        >
                            <Row>

                                <Col>
                                    <Form.Item
                                        name="VoucherCode"
                                        label="Voucher Code"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Input
                                            allowClear
                                            style={{ width: "15em" }}
                                        />
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row>

                                <Col>
                                    <Form.Item
                                        name="ControlNumber"
                                        label="Control Number"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: "10em" }}
                                        >
                                            {
                                                omnivouchersList
                                                    ? sort([
                                                        ...new Set(omnivouchersList.map((x) => x.ControlNumber)),
                                                    ]).map((value) => {
                                                        return (
                                                            <Option value={value} key={value} label={value}>
                                                                {value}
                                                            </Option>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="Status"
                                        label="Voucher Status"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: "7em" }}
                                        >
                                            <Option value={"BURNED"} key={"BURNED"} label="BURNED">
                                                BURNED
                                            </Option>
                                            <Option value={"VALID"} key={"VALID"} label="VALID">
                                                VALID
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="Description"
                                        label="Program Description"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: "10em" }}
                                        >
                                            {
                                                programList
                                                ? sort([
                                                    ...new Set(omnivoucherPrograms.map((x) => x.Description)),
                                                ]).map((value) => {
                                                    return (
                                                        <Option value={value} key={value} label={value}>
                                                            {value}
                                                        </Option>
                                                    );
                                                })
                                                : null
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="IsActive"
                                        label="Program Status"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: "7em" }}
                                        >
                                            <Option value={0} key={false} label="INACTIVE">
                                                INACTIVE
                                            </Option>
                                            <Option value={1} key={true} label="ACTIVE">
                                                ACTIVE
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row>
                                <Col>
                                    <Form.Item
                                        name="IsNational"
                                        label="National"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            allowClear
                                            style={{ width: "7em" }}
                                        >
                                            <Option value={0} key={false} label="No">
                                                No
                                            </Option>
                                            <Option value={1} key={true} label="Yes">
                                                Yes
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="Region"
                                        label="Region"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Separated by comma. Leave BLANK if none"
                                            allowClear
                                            optionLabelProp="label"
                                            style={{ width: "10em" }}
                                        >
                                            {
                                                regionList
                                                    ? regionList.map((region) => {
                                                        return (
                                                            <Option value={region} key={region} label={region}>
                                                                {region}
                                                            </Option>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="SalesOffice"
                                        label="Sales Office"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Separated by comma. Leave BLANK if none"
                                            allowClear
                                            style={{ width: "10em" }}
                                        >
                                            {
                                                salesOfficeList
                                                    ? salesOfficeList.map((value) => {
                                                        return (
                                                            <Option value={value} key={value} label={value}>
                                                                {value}
                                                            </Option>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col>
                                    <Form.Item
                                        name="Territory"
                                        label="Territory"
                                        style={{ marginLeft: "2em" }}
                                    >
                                        <Select
                                            mode="multiple"
                                            placeholder="Separated by comma. Leave BLANK if none"
                                            allowClear
                                            optionLabelProp="label"
                                            style={{ width: "10em" }}
                                        >
                                            {
                                                territoryList
                                                    ? territoryList.map((territory) => {
                                                        return (
                                                            <Option
                                                                value={territory}
                                                                key={territory}
                                                                label={territory}
                                                            >
                                                                {territory}
                                                            </Option>
                                                        );
                                                    })
                                                    : null
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row>
                                <Button
                                    type="danger"
                                    htmlType="submit"
                                    style={{ width: "10em", float: "right", marginLeft: "20px" }}
                                >
                                    Filter
                                </Button>

                                <Button
                                    type="danger"
                                    style={{ width: "10em", float: "right", marginLeft: "20px" }}
                                    onClick={onResetFilter}
                                >
                                    Reset Filter
                                </Button>
                            </Row>

                        </Form>
                    </Panel>
                </Collapse>

                <Table
                    className="omnivoucher-table"
                    rowClassName="row-omnivoucherlist"
                    rowKey={(omnivoucher) => omnivoucher.VoucherID}
                    columns={[...omnivoucherColumns]}
                    dataSource={omnivouchersList}
                    scroll={{ x: "max-content" }}
                    loading={omnivoucherListLoading}
                    pagination={{

                        total: omnivoucherListTotal,
                        page: paginationState.currentPage,
                        pageSize: paginationState.pageSize,

                        pageSizeOptions: [10, 15, 20, 50, 100],

                        onChange: (page, pageSize) => {
                            setPaginationState({
                                currentPage: page,
                                pageSize: pageSize,
                            });
                        },
                        onShowSizeChange: (current, size) => {
                            // console.log('onShowSizeChange', current, size);
                        }
                    }}
                />

            </Card>
        </div>
    );
};

export default OmnivouchersList;
