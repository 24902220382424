import { Link } from "react-router-dom";
import { Menu } from "antd/lib";

import UserService from "../../services/UserService";

const SubMenu = Menu.SubMenu;

const LeftMenu = () => {
  return (
    <Menu mode="horizontal" className="left-menu">
      <SubMenu key="program-submenu" title={<span>Program</span>}>
        <Menu.Item key="1">
          <Link to="/programs">List</Link>
        </Menu.Item>
        {/* <Menu.Item key="2">
          <Link to="/add-program">Add Program</Link>
        </Menu.Item> */}
        <Menu.Item key="3">
          <Link to="/audit-program">Audit</Link>
        </Menu.Item>
      </SubMenu>

      <Menu.Item key="territory">
        <Link to="/territories">Territory</Link>
      </Menu.Item>

      {/* <SubMenu key="game-submenu" title={<span>Game</span>}>
        <Menu.Item key="1">
          <Link to="/games">Game</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/gametypes">Game Type</Link>
        </Menu.Item>
      </SubMenu> */}

      <Menu.Item key="hurdle">
        <Link to="/hurdles">Hurdle</Link>
      </Menu.Item>

      <Menu.Item key="luckycode">
        <Link to="/luckycodes">Lucky Code</Link>
      </Menu.Item>

      {UserService.hasRole(["code-generator-admin"]) ? (
        <Menu.Item key="code-randomizer">
          <Link to="/code-randomizer">Code Randomizer</Link>
        </Menu.Item>
      ) : null}

      {UserService.hasRole(["omnivoucher-admin"]) ? (
        <SubMenu key="omnivoucher-submenu" title={<span>Omnivoucher</span>}>
          <Menu.ItemGroup title="VOUCHER">
            <Menu.Item key="1">
              <Link to="/omnivouchers">List</Link>
            </Menu.Item>
          </Menu.ItemGroup>

          <Menu.ItemGroup title="OMNI PROGRAMS">
            <Menu.Item key="1">
              <Link to="/omnivoucher-programs">List</Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/audit-omnivoucher-programs">Audit</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
      ) : null}
    </Menu>
  );
};
export default LeftMenu;
