import { combineReducers } from "redux";
import programs from "./programs";
import luckycodes from "./luckycodes";
import territories from "./territories";
import audit from "./audit";
// import games from "./games"
// import gametypes from "./gametypes"
import hurdles from "./hurdles";
import programtypes from "./programtypes";

import {
  batchCodesReducer as batchCodes,
  // codesByBatchIdReducer as codesByBatchId,
  downloadLogsByBatchIdReducer as downloadLogsByBatchId,
} from "./codeRandomizer";

import omnivoucherPrograms from "./omnivoucher/omnivoucherPrograms";
import omnivoucherProgramsAudit from "./omnivoucher/omnivoucherProgramsAudit";
import omnivouchers from "./omnivoucher/omnivouchers";
import omnivouchersAudit from "./omnivoucher/omnivouchersAudit";

export default combineReducers({
  programs,
  territories,
  audit,
  // games,
  // gametypes,
  hurdles,
  luckycodes,

  // codeRandomizer,
  batchCodes,
  // codesByBatchId,
  downloadLogsByBatchId,

  omnivoucherPrograms,
  omnivoucherProgramsAudit,
  omnivouchers,
  omnivouchersAudit,
  programtypes,
});
