import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import _ from "lodash";

export var limitTypeOptions = [
  { value: "day", label: "Day" },
  { value: "hour", label: "Hour" },
];

export var programColumns = [
  {
    title: "",
    key: "operation",
    width: 30,
    fixed: "left",
    render: (value) => (
      <a href={`/programs/${value.ProgramID}`}>
        <FontAwesomeIcon icon={faEdit} />
      </a>
    ),
  },
  {
    title: "ID",
    width: 50,
    fixed: "left",
    dataIndex: "ProgramID",
    key: "ProgramID",
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.ProgramID - b.ProgramID,
  },
  {
    title: "BRAND CODES",
    width: 120,
    fixed: "left",
    dataIndex: "BrandCodes",
    key: "BrandCodes",
  },
  { title: "BRAND NAME", width: 120, dataIndex: "BrandName", key: "BrandName" },
  {
    title: "SKU",
    width: 120,
    dataIndex: "SKU",
    key: "SKU",
    render: (value) => (value === "NULL" ? "" : value),
  },
  {
    title: "NATIONAL",
    width: 120,
    dataIndex: "IsNational",
    key: "IsNational",
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "STATUS",
    width: 120,
    dataIndex: "IsProgramActive",
    key: "IsProgramActive",
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "REGION",
    width: 120,
    dataIndex: "Region",
    key: "Region",
    render: (value) => (value === "NULL" ? "" : value),
  },
  {
    title: "TERRITORY",
    width: 120,
    dataIndex: "Territory",
    key: "Territory",
    render: (value) => (value === "NULL" ? "" : value),
  },
  {
    title: "SALES OFFICE",
    width: 120,
    dataIndex: "SalesOffice",
    key: "SalesOffice",
    render: (value) => (value === "NULL" ? "" : value),
  },
  {
    title: "START DATE",
    width: 120,
    dataIndex: "StartDate",
    key: "StartDate",
    sorter: (a, b) => moment(a.StartDate).unix() - moment(b.StartDate).unix(),
  },
  {
    title: "END DATE",
    width: 120,
    dataIndex: "EndDate",
    key: "EndDate",
    sorter: (a, b) => moment(a.EndDate).unix() - moment(b.EndDate).unix(),
  },
  { title: "POINTS", width: 120, dataIndex: "Points", key: "Points" },
  { title: "LIMIT TYPE", width: 120, dataIndex: "LimitType", key: "LimitType" },
  {
    title: "LIMIT FREQUENCY",
    width: 120,
    dataIndex: "LimitFrequency",
    key: "LimitFrequency",
  },
  {
    title: "LIMIT COUNT",
    width: 120,
    dataIndex: "LimitCount",
    key: "LimitCount",
  },
  // {
  //   title: "GAME ENABLED",
  //   width: 120,
  //   dataIndex: "GameEnabled",
  //   key: "GameEnabled",
  //   render: (value) => (value ? "Yes" : "No"),
  // },
  // {
  //   title: "GAME ID",
  //   width: 50,
  //   dataIndex: "GameID",
  //   key: "GameID",
  // },
  // {
  //   title: "GAME START",
  //   width: 120,
  //   dataIndex: "GameStartDate",
  //   key: "GameStartDate",
  // },
  // {
  //   title: "GAME END",
  //   width: 120,
  //   dataIndex: "GameEndDate",
  //   key: "GameEndDate",
  // },

  {
    title: "HURDLE ENABLED",
    width: 100,
    dataIndex: "HurdleEnabled",
    key: "HurdleEnabled",
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "HURDLE ID",
    width: 80,
    dataIndex: "HurdleID",
    key: "HurdleID",
  },
];

export var programAuditColumns = [
  {
    title: "TIMESTAMP",
    width: 50,
    dataIndex: "Timestamp",
    key: "Timestamp",
    render: (value) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "USER",
    width: 50,
    dataIndex: "User",
    key: "User",
    filterSearch: true,
  },
  { title: "ACTION", width: 50, dataIndex: "Action", key: "Action" },
  { title: "ID", width: 40, dataIndex: "ProgramID", key: "ProgramID" },
  {
    title: "OLD VALUE",
    width: 100,
    dataIndex: "OldValue",
    key: "OldValue",
    render: (value) => {
      if (value !== null) {
        let val = JSON.parse(value);
        let obj = Object.keys(val).map((e) => {
          return (
            <p className="audit-para">
              {e} : {val[e]}
            </p>
          );
        });
        return obj;
      } else return "";
    },
  },
  {
    title: "NEW VALUE",
    width: 100,
    dataIndex: "NewValue",
    key: "NewValue",
    render: (value) => {
      let val = JSON.parse(value);
      let obj = Object.keys(val).map((e) => {
        return (
          <p className="audit-para">
            {e} : {val[e]}
          </p>
        );
      });
      return obj;
    },
  },
];

export var programIntColumns = [
  "IsProgramActive",
  "IsNational",
  "LimitCount",
  "LimitFrequency",
  "Points",
  // "GameID",  
  // "GameEnabled"
  "HurdleID",
  "HurdleEnabled",

];

export var fieldNameOptions = ["Region", "Territory", "SalesOffice"];
export var programColumnCompare = [
  "BrandName",
  "BrandCodes",
  "IsNational",
  "Region",
  "Territory",
  "SalesOffice",
  "SKU",
  "IsProgramActive",
  "StartDate",
  "EndDate",
  "Points",
  "LimitType",
  "LimitFrequency",
  "LimitCount",
  // "GameEnabled",
  // "GameID",
  // "GameStartDate",
  // "GameEndDate",
  "HurdleEnabled",
  "HurdleID",
];

// export var gameColumnCompare = [
//   "Link",
//   "Title",
//   "GameType",
//   "BackgroundColor",
//   "TextColor",
// ];

export var hurdleColumnCompare = [
  "Title",
  "IsActive",
  "BackgroundColor",
  "TextColor",
  "Points",
  "BonusPoints",
  "LimitCount",
  "StartDate",
  "EndDate",
];

export var requiredTypes = {
  default: [
    {
      required: true,
      message: "Field must not be blank.",
    },
  ],
  url: [
    {
      type: "url",
      required: true,
      message: "This field must be a valid url.",
    },
  ],
};
export const compare = (a, b, keys) => _.isMatch(a, _.pick(b, keys));

export const sort = (objs) => {
  let sorted = _.sortBy(objs);
  return sorted;
};
export const sortBy = (objs, key) => {
  let sorted = _.sortBy(objs, key);
  return sorted;
};

export const filterByPropertyValue = (objs, property, value) =>
  _.filter(objs, function (o) {
    if (value.includes(o[property])) return o;
  });

export const filterByPropertyContainsValue = (objs, property, value) =>
  _.filter(objs, function (o) {
    if (o[property].includes(value)) return o;
  });
export const filterByMultiplePropertyContainsValue = (
  objs,
  properties,
  value
) => {
  let objsToReturn = [];
  _.filter(objs, function (o) {
    _.forEach(properties, (prop) => {
      if (o[prop].includes(value)) objsToReturn.push(o);
    });
  });
  return _.uniq(objsToReturn, "TerritoryCode");
};

export const filterSearchResult = (objs, values) => {
  var keys = Object.keys(values);
  var tempList = objs;
  _.forEach(keys, (key) => {
    if (
      key !== "StartDate" &&
      key !== "EndDate" &&
      key !== "IsNational" &&
      key !== "IsProgramActive"
    )
      tempList = _.filter(tempList, function (o) {
        if (o[key] !== null && o[key].includes(values[key])) return o;
      });
    else if (key === "StartDate" || key === "EndDate") {
      tempList = _.filter(tempList, function (o) {
        let timestamp = new Date(o.Timestamp);
        let start = new Date(values["StartDate"]);
        let end = new Date(values["EndDate"]);
        if (timestamp >= start && timestamp <= end) return o;
      });
    } else if (key === "IsNational" || key === "IsProgramActive") {
      tempList = _.filter(tempList, function (o) {
        if (o[key].toString() === values[key].toString()) return o;
      });
    }
  });

  return tempList;
};
