import { SUCCESS_SUFFIX } from "redux-axios-middleware";
import HttpService from "../services/HttpService";

const LIST_AUDIT_PROGRAM = 'LIST_AUDIT_PROGRAM';
const ADD_AUDIT_PROGRAM = 'ADD_AUDIT_PROGRAM';

const auditLogsReducer = (state = [], action) => {
  switch (action.type) {
    case LIST_AUDIT_PROGRAM + SUCCESS_SUFFIX:
      return action.payload.data;

    default:
      return state;
  }
};

export default auditLogsReducer;

export const allProgramAuditLogs = () => ({
  type: LIST_AUDIT_PROGRAM,
  payload: {
    request: {
      url: '/audit/programs',
    },
  },
});

export const addProgramAuditLogs = audit => {
  return {
    type: ADD_AUDIT_PROGRAM,
    payload: {
      request: {
        url: '/audit/programs',
        method: HttpService.HttpMethods.POST,
        data: audit,
      },
    },
  }
};
