import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allHurdles, addHurdle, editHurdle } from "../../modules/hurdles";
import { SketchPicker } from "react-color";
import reactCSS from "reactcss";

import {
  Input,
  Select,
  Button,
  message,
  Modal,
  Form,
  DatePicker,
} from "antd/lib";
import {
  hurdleColumnCompare,
  compare,
  requiredTypes,
} from "../../helpers/helpers";

import moment from "moment";
const { Option } = Select;
const { RangePicker } = DatePicker;
const HurdleModal = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(props.hurdle);
  const [form] = Form.useForm();
  const [bgColor, setBgColor] = useState({
    display: false,
    color: "#FFFFFF",
  });
  // const [dates, setDates] = useState(null);
  // const [hackValue, setHackValue] = useState(null);
  // const [value, setValue] = useState(null);

  const [txtColor, setTxtColor] = useState({
    display: false,
    color: "#000000",
  });
  useEffect(() => {
    if (props.action === "Edit" && props.hurdle) {
      props.hurdle.IsActive = props.hurdle.IsActive === 1 ? "Yes" : "No";
      // let hurdle = props.hurdle;

      setFormData(props.hurdle);
      form.setFieldsValue(props.hurdle);
      if (props.hurdle) {
        setBgColor((prevState) => {
          return { ...prevState, color: props.hurdle.BackgroundColor };
        });
        setTxtColor((prevState) => {
          return { ...prevState, color: props.hurdle.TextColor };
        });
      }
    } else {
      form.resetFields();
      setFormData(null);
    }
  }, [props.hurdle]);

  const doesHurdleExists = (values) => {
    let exists = false;
    if (props.hurdles.length > 0) {
      for (let index = 0; index <= props.hurdles.length; index++) {
        exists = compare(props.hurdles[index], values, hurdleColumnCompare);
        if (exists) break;
      }
    }
    return exists;
  };
  const onFinish = (e) => {
    e.StartDate = e.Date[0].format("YYYY-MM-DD") + " 00:00:00";
    e.EndDate = e.Date[1].format("YYYY-MM-DD") + " 23:59:59";
    delete e["Date"];
    // console.log(e);
    let exists = doesHurdleExists(e);

    if (!exists) {
      if (props.action === "Edit") {
        if (e.IsActive === "Yes" || e.IsActive === "No") {
          e.IsActive = e.IsActive === "Yes" ? 1 : 0;
        } else {
          e.IsActive = parseInt(e.IsActive);
        }

        dispatch(editHurdle(props.hurdle.ID, e))
          .then((response) => {
            let data = response.payload.data;
            if (data.success) {
              message.success(`Hurdle updated successfully!`, 5);
              dispatch(allHurdles());
            } else {
              message.error("Error on updating hurdle");
            }
          })
          .catch((e) => {
            message.error("Error on updating hurdle");
          });
      } else {
        dispatch(addHurdle(e))
          .then((response) => {
            let data = response.payload.data;
            if (data.success) {
              message.success(`Hurdle adding successfully!`, 5);
              dispatch(allHurdles());
            } else {
              message.error("Error on adding hurdle");
            }
          })
          .catch((e) => {
            message.error("Error on adding hurdle");
          });
      }
    } else {
      message.error("No changes performed or hurdle already exists.");
    }
  };
  const bgStyles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: bgColor.color,
      },
      swatch: {
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const txtStyles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "14px",
        borderRadius: "2px",
        background: txtColor.color,
      },
      swatch: {
        background: "#fff",
        borderRadius: "1px",
        boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
        display: "inline-block",
        cursor: "pointer",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const handleBgClick = () => {
    setBgColor((prevState) => {
      return { ...prevState, display: !bgColor.display };
    });
  };

  const handleBgClose = () => {
    setBgColor((prevState) => {
      return { ...prevState, display: false };
    });
  };

  const handleBgChange = (color) => {
    setBgColor((prevState) => {
      return { ...prevState, color: color.hex };
    });
    form.setFieldsValue({ BackgroundColor: color.hex });
  };

  const handleTxtClick = () => {
    setTxtColor((prevState) => {
      return { ...prevState, display: !txtColor.display };
    });
  };

  const handleTxtClose = () => {
    setTxtColor((prevState) => {
      return { ...prevState, display: false };
    });
  };

  const handleTxtChange = (color) => {
    setTxtColor((prevState) => {
      return { ...prevState, color: color.hex };
    });
    form.setFieldsValue({ TextColor: color.hex });
  };

  const bgPrefix = (
    <div style={{ display: "inline" }}>
      <div style={bgStyles.swatch} onClick={handleBgClick}>
        <div style={bgStyles.color} />
      </div>
      {bgColor.display ? (
        <div style={bgStyles.popover}>
          <div style={bgStyles.cover} onClick={handleBgClose} />
          <SketchPicker color={bgColor.color} onChange={handleBgChange} />
        </div>
      ) : null}
    </div>
  );
  const txtPrefix = (
    <div style={{ display: "inline" }}>
      <div style={txtStyles.swatch} onClick={handleTxtClick}>
        <div style={txtStyles.color} />
      </div>
      {txtColor.display ? (
        <div style={txtStyles.popover}>
          <div style={txtStyles.cover} onClick={handleTxtClose} />
          <SketchPicker color={txtColor.color} onChange={handleTxtChange} />
        </div>
      ) : null}
    </div>
  );

  /* const disabledDate = (current) => {
        if (!dates) {
          return false;
        }
        const present = moment().add(-1, 'days') >= current;
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 7;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 7;
        return !!present || !!tooEarly || !!tooLate;
      };

      const onOpenChange = (open) => {
        if (open) {
          setHackValue([null, null]);
          setDates([null, null]);
        } else {
          setHackValue(null);
        }
    }; */

  return (
    <Modal
      title={`${props.action} Hurdle`}
      visible={props.visible}
      footer={null}
      onCancel={props.onCancel}
      className="modal-hurdle"
    >
      <div>
        <Form
          id="hurdleForm"
          form={form}
          onFinish={onFinish}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={props.action === "Edit" && formData ? formData : null}
        >
          {props.columns.map((x) => {
            if (x.key === "Title" || x.key === "Filename")
              return (
                <Form.Item
                  name={x.key}
                  label={x.name}
                  rules={x.required}
                  style={{ marginTop: "0px" }}
                >
                  <Input
                    maxLength={x.maxLength}
                    placeholder={
                      x.key === "Filename"
                        ? "(e.g. marlboro.png, mbm100c.png)"
                        : ""
                    }
                  />
                </Form.Item>
              );
            if (x.key === "IsActive")
              return (
                <Form.Item
                  name={x.key}
                  label={x.name}
                  rules={x.required}
                  style={{ marginTop: "0px" }}
                >
                  <Select allowClear>
                    <Option value="1">Yes</Option>
                    <Option value="0">No</Option>
                  </Select>
                </Form.Item>
              );
            else if (x.key === "BackgroundColor")
              return (
                <Form.Item
                  name={x.key}
                  label={x.name}
                  rules={x.required}
                  style={{ marginTop: "0px" }}
                >
                  <Input
                    type="text"
                    prefix={bgPrefix}
                    value={bgColor.color}
                    disabled={true}
                  />
                </Form.Item>
              );
            else if (x.key === "TextColor")
              return (
                <Form.Item
                  name={x.key}
                  label={x.name}
                  rules={x.required}
                  style={{ marginTop: "0px" }}
                >
                  <Input
                    type="text"
                    prefix={txtPrefix}
                    value={txtColor.color}
                    disabled={true}
                  />
                </Form.Item>
              );
            else if (x.key.includes("Points") || x.key.includes("Count"))
              return (
                <Form.Item
                  name={x.key}
                  label={x.name}
                  rules={x.required}
                  style={{ marginTop: "0px" }}
                >
                  <Input
                    type={x.type}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, x.maxLength);
                    }}
                    onKeyDown={(evt) =>
                      (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                      evt.preventDefault()
                    }
                  />
                </Form.Item>
              );
          })}
          <Form.Item
            name="Date"
            label="Date"
            rules={requiredTypes.default}
            style={{ marginTop: "0px" }}
          >
            <RangePicker
              style={{ width: "100%" }}
              disabledDate={(current) => {
                return (
                  moment().add(-1, "days") >= current ||
                  moment().add(6, "days") <= current
                );
              }}
            />
            {/* <RangePicker
                            style={{ width: "100%" }}
                            value={hackValue || value}
                            disabledDate={disabledDate}
                            onCalendarChange={(val) => setDates(val)}
                            onChange={(val) => setValue(val)}
                            onOpenChange={onOpenChange}
                            /> */}
          </Form.Item>
          <Button type="danger" htmlType="submit" style={{ float: "right" }}>
            SUBMIT
          </Button>
        </Form>
      </div>
    </Modal>
  );
};
export default HurdleModal;
