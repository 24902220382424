import Keycloak from "keycloak-js";
// const _kc = Keycloak.KeycloakInstance = Keycloak();
// const _kc = new Keycloak('./keycloak_dev.json');

const keycloakConfig = {
  url: process.env.REACT_APP_AUTH_URL,
  realm: process.env.REACT_APP_REALM,
  clientId: process.env.REACT_APP_WEBCLIENTID,
};
const _kc = new Keycloak(keycloakConfig);

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
      pkceMethod: "S256",
    })
    .then((authenticated) => {
      // if (authenticated) {
      console.log(keycloakConfig);
      onAuthenticatedCallback();
      // } else {
      //   doLogin();
      // }
    });
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getName = () => _kc.tokenParsed?.name;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getName,
};

export default UserService;
