import { SUCCESS_SUFFIX } from "redux-axios-middleware";
import HttpService from "../services/HttpService";

const LIST_HURDLE = 'LIST_HURDLE';
const ADD_HURDLE = 'ADD_HURDLE';
const EDIT_HURDLE = 'EDIT_HURDLE';

const hurdleReducer = (state = [], action) => {
  switch (action.type) {
    case LIST_HURDLE + SUCCESS_SUFFIX:
      return action.payload.data;

    default:
      return state;
  }
};

export default hurdleReducer;

export const allHurdles = () => ({
  type: LIST_HURDLE,
  payload: {
    request: {
      url: '/api/hurdles',
    },
  },
});

export const addHurdle = hurdle => {
  return {
    type: ADD_HURDLE,
    payload: {
      request: {
        url: '/api/hurdles',
        method: HttpService.HttpMethods.POST,
        data: hurdle,
      },
    },
  }
};

export const editHurdle = (id,hurdle) => {
  return {
    type: EDIT_HURDLE,
    payload: {
      request: {
        url: `/api/hurdles/${id}`,
        method: HttpService.HttpMethods.PUT,
        data: hurdle
      },
    },
  }
};