import { SUCCESS_SUFFIX } from "redux-axios-middleware";
import HttpService from "../../services/HttpService";

const LIST_AUDIT_PROGRAM = 'LIST_AUDIT_PROGRAM';
const ADD_AUDIT_PROGRAM = 'ADD_AUDIT_PROGRAM';

function allOmnivoucherProgramAuditLogs () {
  return {
    type: LIST_AUDIT_PROGRAM,
    payload: {
      request: {
        url: '/audit/omnivoucher-programs',
        method: HttpService.HttpMethods.GET,
      },
    },
  }
};

function addOmnivoucherProgramAuditLogs (audit) {
  return {
    type: ADD_AUDIT_PROGRAM,
    payload: {
      request: {
        url: '/audit/omnivoucher-programs',
        method: HttpService.HttpMethods.POST,
        data: audit,
      },
    },
  }
};

const omnivoucherProgramAuditLogsReducer = (state = [], action) => {

  // console.log('omnivoucherProgramAuditLogsReducer()', state, ' ', action);

  switch (action.type) {
    case LIST_AUDIT_PROGRAM + SUCCESS_SUFFIX:
      return action.payload.data;

    default:
      return state;
  }
};

export {
  allOmnivoucherProgramAuditLogs,
  addOmnivoucherProgramAuditLogs,
};

export default omnivoucherProgramAuditLogsReducer;
