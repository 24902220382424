import { SUCCESS_SUFFIX } from "redux-axios-middleware";
import HttpService from "../services/HttpService";

const LIST_PROGRAM = 'LIST_PROGRAM';
const ADD_PROGRAM = 'ADD_PROGRAM';
const EDIT_PROGRAM = 'EDIT_PROGRAM';
const DELETE_PROGRAM = 'DELETE_PROGRAM';

const programsReducer = (state = [], action) => {
  switch (action.type) {
    case LIST_PROGRAM + SUCCESS_SUFFIX:
      return action.payload.data;

    case DELETE_PROGRAM:
      return state.filter((program) => program.ProgramID !== action.payload.program.ProgramID);

    default:
      return state;
  }
};

export default programsReducer;

export const allPrograms = () => ({
  type: LIST_PROGRAM,
  payload: {
    request: {
      url: '/api/programs',
    },
  },
});

export const addProgram = program => {
  return {
    type: ADD_PROGRAM,
    payload: {
      request: {
        url: '/api/programs',
        method: HttpService.HttpMethods.POST,
        data: program,
      },
    },
  }
};


export const editProgram = (id,program) => {
  return {
    type: EDIT_PROGRAM,
    payload: {
      request: {
        url: `/api/programs/${id}`,
        method: HttpService.HttpMethods.PUT,
        data: program
      },
    },
  }
};
