import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import StoreService from "../services/StoreService";
import RenderOnAnonymous from "./RenderOnAnonymous";
import RenderOnAuthenticated from "./RenderOnAuthenticated";
import Welcome from "./Welcome";
import MainNavigation from "./Navigation/MainNavigation";
const store = StoreService.setup();

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
     
        <RenderOnAnonymous>
          <Welcome/>
        </RenderOnAnonymous>
        <RenderOnAuthenticated>
          <MainNavigation/>
        </RenderOnAuthenticated>
      
    </BrowserRouter>
  </Provider>
);

export default App;
