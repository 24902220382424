import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import _ from "lodash";

export var fieldNameOptions = ["Region", "Territory", "SalesOffice"];
export var emptyFields = {
  Region: [],
  Territory: [],
  SalesOffice: [],
  //   SKU: "",
};
export var programColumns = [
  {
    title: "ID",
    width: 50,
    fixed: "left",
    dataIndex: "ProgramID",
    key: "ProgramID",
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.ProgramID - b.ProgramID,
  },
  {
    title: "BRAND CODES",
    width: 120,
    fixed: "left",
    dataIndex: "BrandCodes",
    key: "BrandCodes",
  },
  {
    title: "TYPE",
    width: 50,
    dataIndex: "Type",
    key: "Type",
    fixed: "left",
  },
  { title: "BRAND NAME", width: 120, dataIndex: "BrandName", key: "BrandName" },
  {
    title: "SKU",
    width: 120,
    dataIndex: "SKU",
    key: "SKU",
    render: (value) => (value === "NULL" ? "" : value),
  },
  {
    title: "NATIONAL",
    width: 120,
    dataIndex: "IsNational",
    key: "IsNational",
    render: (value) => (value ? "Yes" : "No"),
  },
  // HIDE FOR PRODUCTION DEPLOYMENT
  // {
  //   title: "AYO",
  //   width: 120,
  //   dataIndex: "IsAYO",
  //   key: "IsAYO",
  //   render: (value) => (value ? "Yes" : "No"),
  // },
  // {
  //   title: "CONTACT FLEX 2",
  //   width: 120,
  //   dataIndex: "ContactFlex2",
  //   key: "ContactFlex2",
  //   render: (value) => (value === "NULL" ? "" : value),
  // },
  // {
  //   title: "CONTACT FLEX 3",
  //   width: 120,
  //   dataIndex: "ContactFlex3",
  //   key: "ContactFlex3",
  //   render: (value) => (value === "NULL" ? "" : value),
  // },
  {
    title: "STATUS",
    width: 120,
    dataIndex: "IsProgramActive",
    key: "IsProgramActive",
    render: (value) => (value ? "Yes" : "No"),
  },
  {
    title: "REGION",
    width: 120,
    dataIndex: "Region",
    key: "Region",
    render: (value) => (value === "NULL" ? "" : value),
  },
  {
    title: "TERRITORY",
    width: 120,
    dataIndex: "Territory",
    key: "Territory",
    render: (value) => (value === "NULL" ? "" : value),
  },
  {
    title: "SALES OFFICE",
    width: 120,
    dataIndex: "SalesOffice",
    key: "SalesOffice",
    render: (value) => (value === "NULL" ? "" : value),
  },
  {
    title: "START DATE",
    width: 120,
    dataIndex: "StartDate",
    key: "StartDate",
    sorter: (a, b) => moment(a.StartDate).unix() - moment(b.StartDate).unix(),
  },
  {
    title: "END DATE",
    width: 120,
    dataIndex: "EndDate",
    key: "EndDate",
    sorter: (a, b) => moment(a.EndDate).unix() - moment(b.EndDate).unix(),
  },
  { title: "POINTS", width: 120, dataIndex: "Points", key: "Points" },
  { title: "LIMIT TYPE", width: 120, dataIndex: "LimitType", key: "LimitType" },
  {
    title: "LIMIT FREQUENCY",
    width: 120,
    dataIndex: "LimitFrequency",
    key: "LimitFrequency",
  },
  {
    title: "LIMIT COUNT",
    width: 120,
    dataIndex: "LimitCount",
    key: "LimitCount",
  },
  {
    title: "HURDLE ID",
    width: 80,
    dataIndex: "HurdleID",
    key: "HurdleID",
  },
  {
    title: "LUCKYCODE ID",
    width: 80,
    dataIndex: "LuckyCodeID",
    key: "LuckyCodeID",
  },

  // {
  //   title: "GAME ID",
  //   width: 50,
  //   dataIndex: "GameID",
  //   key: "GameID",
  // },
];

export var programAuditColumns = [
  {
    title: "TIMESTAMP",
    width: 50,
    dataIndex: "Timestamp",
    key: "Timestamp",
    render: (value) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "USER",
    width: 50,
    dataIndex: "User",
    key: "User",
    filterSearch: true,
  },
  { title: "ACTION", width: 50, dataIndex: "Action", key: "Action" },
  { title: "ID", width: 40, dataIndex: "ProgramID", key: "ProgramID" },
  {
    title: "OLD VALUE",
    width: 100,
    dataIndex: "OldValue",
    key: "OldValue",
    render: (value) => {
      if (value !== null) {
        let val = JSON.parse(value);
        let obj = Object.keys(val).map((e) => {
          return (
            <p className="audit-para">
              {e} : {val[e]}
            </p>
          );
        });
        return obj;
      } else return "";
    },
  },
  {
    title: "NEW VALUE",
    width: 100,
    dataIndex: "NewValue",
    key: "NewValue",
    render: (value) => {
      let val = JSON.parse(value);
      let obj = Object.keys(val).map((e) => {
        return (
          <p className="audit-para">
            {e} : {val[e]}
          </p>
        );
      });
      return obj;
    },
  },
];

export var programIntColumns = [
  "IsProgramActive",
  "IsNational",
  "LimitCount",
  "LimitFrequency",
  "Points",
  // "GameID",
  // "GameEnabled"
  "HurdleID",
  "LuckyCodeID",
  "VolumeFlag",
];

export const filterSearchResult = (objs, values) => {
  // console.log(values);
  var keys = Object.keys(values);
  var tempList = objs;
  _.forEach(keys, (key) => {
    if (
      key !== "StartDate" &&
      key !== "EndDate" &&
      key !== "IsNational" &&
      key !== "IsProgramActive" &&
      key !== "ProgramType"
    )
      tempList = _.filter(tempList, function (o) {
        if (o[key] !== null && o[key].includes(values[key])) return o;
      });
    else if (key === "StartDate" || key === "EndDate") {
      tempList = _.filter(tempList, function (o) {
        let timestamp = new Date(o.Timestamp);
        let start = new Date(values["StartDate"]);
        let end = new Date(values["EndDate"]);
        if (timestamp >= start && timestamp <= end) return o;
      });
    } else if (key === "ProgramType") {
      tempList = _.filter(tempList, function (o) {
        if (o[key].toString() === values[key].toString()) return o;
      });
    } else if (key === "IsNational" || key === "IsProgramActive") {
      tempList = _.filter(tempList, function (o) {
        if (!!o[key] === values[key]) return o;
      });
    }
  });

  return tempList;
};

export const fortmatExportList = (list, types) => {
  let formattedList = list.map((p) => ({
    ID: p.ProgramID,
    BrandName: p.BrandName,
    BrandCodes: p.BrandCodes,
    ProgramType: types.find((x) => x.ID === p.ProgramType).Type,
    Status: p.IsProgramActive === 1 ? "Yes" : "No",
    National: p.IsNational === 1 ? "Yes" : "No",
    AYO: p.IsAYO === 1 ? "Yes" : "No",
    ContactFlex2: p.ContactFlex2 === "NULL" ? "" : p.ContactFlex2,
    ContactFlex3: p.ContactFlex3 === "NULL" ? "" : p.ContactFlex3,
    Region: p.Region === "NULL" ? "" : p.Region,
    Territory: p.Territory === "NULL" ? "" : p.Territory,
    SalesOffice: p.SalesOffice === "NULL" ? "" : p.SalesOffice,
    SKU: p.SKU === "NULL" ? "" : p.SKU,
    StartDate: p.StartDate,
    EndDate: p.EndDate,
    LimitType: p.LimitType,
    LimitFrequency: p.LimitFrequency,
    LimitCount: p.LimitCount,
    HurdleID: p.HurdleID,
    VolumeFlag: p.VolumeFlag === 1 ? "Yes" : "No",
    LuckyCodeID: p.LuckyCodeID,
  }));
  return formattedList;
};

export const formItemLayout = {
  labelCol: {
    xs: {
      span: 10,
    },
    sm: {
      span: 7,
    },
    md: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 10,
    },
    sm: {
      span: 15,
    },
    md: {
      span: 10,
    },
  },
};

export var programColumnCompare = [
  "BrandName",
  "BrandCodes",
  "IsNational",
  "Region",
  "Territory",
  "SalesOffice",
  "SKU",
  "IsProgramActive",
  "StartDate",
  "EndDate",
  "Points",
  "LimitType",
  "LimitFrequency",
  "LimitCount",
  // "GameEnabled",
  // "GameID",
  // "GameStartDate",
  // "GameEndDate",
  "ProgramType",
  "HurdleID",
  "LuckyCodeID",
  "VolumeFlag",
];
