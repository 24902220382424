import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Input, Button, message, Modal, Form, Spin, Table } from "antd/lib";
import {
  allRandomizerBatchCodes,
  allCodesByBatchId,
  getBatchCodeInfo,
  createdDownloadLog,
  allDownloadLogsByBatchId,
} from "../../modules/codeRandomizer";
import moment from "moment";

const DownloadHistoryModal = (props) => {
  const downloadHistoryColumns = [
    {
      title: "USER",
      width: 50,
      dataIndex: "DownloadedBy",
      key: "DownloadedBy",
      name: "DownloadedBy",
      maxLength: 3,
    },
    {
      title: "DATE",
      width: 50,
      dataIndex: "DownloadedDate",
      key: "DownloadedDate",
      name: "DownloadedDate",
      render: (value) => moment(value).format("YYYY-MM-DD hh:mm:ss A"),
    },
  ];

  // const defaultFooter = () => `TOTAL: ${props.downloadHistoryList.length}`;

  return (
    <Modal
      title={`DOWNLOAD HISTORY`}
      visible={props.visible}
      footer={null}
      onCancel={props.onCancel}
      className="modal-codeRandomizer"
      width={500}
    >
      <Table
        className="code-randomizer-table"
        rowClassName="code-randomizer-rows"
        rowKey={(code) => code.ID}
        columns={downloadHistoryColumns}
        dataSource={props.downloadHistoryList}
        scroll={{ y: 250 }}
        footer={() => `TOTAL: ${props.downloadHistoryList.length}`}
      />
    </Modal>
  );
};
export default DownloadHistoryModal;
