import { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import LeftMenu from "./LeftMenu";
import RightMenu from "./RightMenu";
import SideMenu from "./SideMenu";
import ProgramDetails from "../Program/ProgramDetails";
import ProgramAdd from "../Program/ProgramAdd";
import ProgramList from "../Program/ProgramList";
import ProgramAudit from "../Program/ProgramAudit";
// import GameList from "../Game/GameList";
import NoMatch from "../NoMatch";
import RolesRoute from "../RolesRoute";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Layout, Drawer, Button, Row, Col } from "antd/lib";
import TerritoryList from "../Territory/TerritoryList";
// import GameTypeList from "../Game/GameTypeList";
import HurdleList from "../Hurdle/HurdleList";
import CodeRandomizer from "../CodeRandomizer/CodeRandomizer";
import LuckyCodeList from "../LuckyCode/LuckyCodeList";
import UserService from "../../services/UserService";

import OmnivoucherProgramList from "../Omnivoucher/OmnivoucherProgramList";
import OmnivoucherProgramAudit from "../Omnivoucher/OmnivoucherProgramAudit";
import OmnivouchersList from "../Omnivoucher/OminvouchersList";
// import OmnivouchersAudit from "../Omnivoucher/OmnivoucherProgramAudit";

const { Content, Footer } = Layout;

const MainNavigation = () => {
  const [toggle, setToggle] = useState(false);

  const toggleDrawer = () => {
    setToggle(!toggle);
  };

  return (
    <>
      <Layout>
        <div className="main-nav">
          <Row className="main-desktop">
            <Col span={12}>
              <LeftMenu />
            </Col>
            <Col span={12}>
              <RightMenu />
            </Col>
          </Row>

          <Row className="main-mobile">
            <Col>
              <Button id="btnDrawer" type="primary" onClick={toggleDrawer}>
                <FontAwesomeIcon icon={faBars} />
              </Button>
              <Drawer
                placement="left"
                closable={true}
                onClose={toggleDrawer}
                visible={toggle}
              >
                <SideMenu />
              </Drawer>
            </Col>
          </Row>
        </div>
        <Content className="site-layout">
          <Switch>
            <RolesRoute
              exact
              path="/"
              roles={[
                "upccms-member",
                "code-generator-admin",
                "omnivoucher-admin",
              ]}
            >
              <Redirect
                to={
                  UserService.hasRole(["upccms-member"])
                    ? "/programs"
                    : UserService.hasRole(["omnivoucher-admin"])
                    ? "/omnivouchers"
                    : "/code-randomizer"
                }
              />
            </RolesRoute>
            {/* <RolesRoute exact path="/" roles={["upccms-member"]}>
              <ProgramList />
            </RolesRoute> */}
            <RolesRoute exact path="/programs" roles={["upccms-member"]}>
              <ProgramList />
            </RolesRoute>
            <RolesRoute
              exact
              path="/programs/:programId"
              roles={["upccms-member"]}
            >
              <ProgramDetails />
            </RolesRoute>
            <RolesRoute exact path="/add-program" roles={["upccms-member"]}>
              <ProgramAdd />
            </RolesRoute>
            <RolesRoute exact path="/audit-program" roles={["upccms-member"]}>
              <ProgramAudit />
            </RolesRoute>
            <RolesRoute exact path="/territories" roles={["upccms-member"]}>
              <TerritoryList />
            </RolesRoute>
            {/* <RolesRoute exact path="/games" roles={["upccms-member"]}>
              <GameList />
            </RolesRoute>
            <RolesRoute exact path="/gametypes" roles={["upccms-member"]}>
              <GameTypeList />
            </RolesRoute> */}
            <RolesRoute exact path="/hurdles" roles={["upccms-member"]}>
              <HurdleList />
            </RolesRoute>

            <RolesRoute exact path="/luckycodes" roles={["upccms-member"]}>
              <LuckyCodeList />
            </RolesRoute>

            <RolesRoute
              exact
              path="/code-randomizer"
              roles={["code-generator-admin"]}
            >
              <CodeRandomizer />
            </RolesRoute>

            <RolesRoute
              exact
              path="/omnivouchers"
              roles={["omnivoucher-admin"]}
            >
              <OmnivouchersList />
            </RolesRoute>
            {/* <RolesRoute
              exact
              path="/audit-omnivoucher"
              roles={["omnivoucher-admin"]}
            >
              <OmnivouchersAudit />
            </RolesRoute> */}
            <RolesRoute
              exact
              path="/omnivoucher-programs"
              roles={["omnivoucher-admin"]}
            >
              <OmnivoucherProgramList />
            </RolesRoute>
            <RolesRoute
              exact
              path="/audit-omnivoucher-programs"
              roles={["omnivoucher-admin"]}
            >
              <OmnivoucherProgramAudit />
            </RolesRoute>

            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          UNIQUE PACK CODE CMS ©2021
        </Footer>
      </Layout>
    </>
  );
};
export default MainNavigation;
