import { SUCCESS_SUFFIX } from "redux-axios-middleware";
import HttpService from "../services/HttpService";

const LIST_LUCKYCODE = 'LIST_LUCKYCODE';
const ADD_LUCKYCODE = 'ADD_LUCKYCODE';
const EDIT_LUCKYCODE = 'EDIT_LUCKYCODE';
const DELETE_LUCKYCODE = 'DELETE_LUCKYCODE';

const luckyCodeReducer = (state = [], action) => {
  switch (action.type) {
    case LIST_LUCKYCODE + SUCCESS_SUFFIX:
      return action.payload.data;
    case DELETE_LUCKYCODE:
        return {...state};
    default:
      return state;
  }
};

export default luckyCodeReducer;

export const allLuckyCodes = () => ({
  type: LIST_LUCKYCODE,
  payload: {
    request: {
      url: '/api/luckycodes',
    },
  },
});

export const addLuckyCode = (luckycode,luckycodecombinations) => {
  return {
    type: ADD_LUCKYCODE,
    payload: {
      request: {
        url: '/api/luckycodes',
        method: HttpService.HttpMethods.POST,
        data: {luckycode,luckycodecombinations},
      },
    },
  }
};

export const editLuckyCode = (id, luckycode, luckycodecombinations) => {
  return {
    type: EDIT_LUCKYCODE,
    payload: {
      request: {
        url: `/api/luckycodes/${id}`,
        method: HttpService.HttpMethods.PUT,
        data: {luckycode, luckycodecombinations}
      },
    },
  }
};

export const deleteLuckyCode = (id) => {
    return {
      type: DELETE_LUCKYCODE,
      payload: {
        request: {
          url: `/api/luckycodes/${id}?m=SOFT_DELETE`,
          method: HttpService.HttpMethods.PUT,
          data: { id: id }
        },
      },
    }
  };
