import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allHurdles } from "../../modules/hurdles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { requiredTypes } from "../../helpers/helpers";
import HurdleModal from "./HurdleModal";
import {
  Table,
  Card,
  Input,
  Select,
  Button,
  message,
  Row,
  Col,
  Modal,
  Form,
} from "antd/lib";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  filterByMultiplePropertyContainsValue,
  sortBy,
} from "../../helpers/helpers";
import * as XLSX from "xlsx";

const HurdleList = () => {
  var hurdleColumns = [
    {
      title: "EDIT",
      key: "operation",
      width: 30,
      fixed: "left",
      render: (text, record, index) => (
         <a
          onClick={(e) => {
            record.Date = [moment.utc(record.StartDate), moment.utc(record.EndDate)];
            setHurdle(record);
            openModal("Edit");
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </a>
      ),
    },
    {
      title: "ID",
      width: 50,
      dataIndex: "ID",
      key: "ID",
      name: "ID",
      maxLength: 3,
    },
    {
      title: "TITLE",
      width: 200,
      dataIndex: "Title",
      key: "Title",
      name: "Title",
      maxLength: 30,
      required: requiredTypes.default,
    },
    {
      title: "FILENAME",
      width: 200,
      dataIndex: "Filename",
      key: "Filename",
      name: "File Name",
      maxLength: 30,
      required: requiredTypes.default,
    },
    {
      title: "ENABLED",
      width: 50,
      dataIndex: "IsActive",
      key: "IsActive",
      name: "Enabled",
      maxLength: 10,
      render: (value) => (value ? "Yes" : "No"),
      required: requiredTypes.default,
    },
    {
      title: "BACKGROUND COLOR",
      width: 70,
      dataIndex: "BackgroundColor",
      key: "BackgroundColor",
      name: "Background Color",
      maxLength: 7,
      required: requiredTypes.default,
      render: (text, record, index) => (
        <div className="swatch">
          <div className="color-border">
            <div
              className="color"
              style={{
                background: record.BackgroundColor,
              }}
            ></div>
          </div>
        </div>
      ),
    },
    {
      title: "TEXT COLOR",
      width: 70,
      dataIndex: "TextColor",
      key: "TextColor",
      name: "Text Color",
      maxLength: 7,
      required: requiredTypes.default,
      render: (text, record, index) => (
        <div className="swatch">
          <div className="color-border">
            <div
              className="color"
              style={{
                background: record.TextColor,
              }}
            ></div>
          </div>
        </div>
      ),
    },

    {
      title: "POINTS",
      width: 50,
      dataIndex: "Points",
      key: "Points",
      name: "Points",
      maxLength: 2,
      required: requiredTypes.default,
      type: "number"
    },
    {
      title: "BONUS POINTS",
      width: 60,
      dataIndex: "BonusPoints",
      key: "BonusPoints",
      name: "Bonus Points",
      maxLength: 4,
      required: requiredTypes.default,
      type: "number"
    },
    {
      title: "LIMIT",
      width: 50,
      dataIndex: "LimitCount",
      key: "LimitCount",
      name: "Limit",
      maxLength: 4,
      required: requiredTypes.default,
      type: "number"
    },
    {
      title: "START DATE",
      width: 120,
      dataIndex: "StartDate",
      key: "StartDate",
      name: "Start Date",
      required: requiredTypes.default,
      render: (value) =>  moment.utc(value).format("YYYY-MM-DD HH:mm:ss")
    },
    {
      title: "END DATE",
      width: 120,
      dataIndex: "EndDate",
      key: "EndDate",
      name: "End Date",
      required: requiredTypes.default,
      render: (value) =>  moment.utc(value).format("YYYY-MM-DD HH:mm:ss")
    },
  ];

  const dispatch = useDispatch();
  const { hurdles } = useSelector((state) => state);
  const [hurdleList, setHurdleList] = useState();
  const [hurdle, setHurdle] = useState();
  const [visible, setVisible] = useState(false);
  const [action, setAction] = useState();
  useEffect(() => {
    dispatch(allHurdles());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setHurdleList(sortBy(hurdles, "ID"));
  }, [hurdles]); // eslint-disable-line react-hooks/exhaustive-deps

  const openModal = (action) => {
    showModal();
    setAction(action);
  };
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onDownload = (e) => {
    let list = hurdleList;
    list.forEach((x) => {
      x.Enabled = parseInt(x.IsActive) === 1 ? "Yes":"No";
      x.StartDate = moment(x.StartDate).format("YYYY-MM-DD HH:mm:ss");
      x.EndDate = moment(x.EndDate).format("YYYY-MM-DD HH:mm:ss");
      delete x["IsActive"];
    }
    );
    const ws = XLSX.utils.json_to_sheet(list);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Hurdles");
    XLSX.writeFile(wb, `Hurdles.xlsx`);
    if (hurdleList.length !== hurdleList.length)
      message.success("Filtered Hurdles downloaded!");
    else {
      message.success("All Hurdles downloaded!");
    }
  };
  return (
    <div className="row">
      <Card title="HURDLE LIST">
        <Row>
          <Col flex={3}>
            <Button
              className="btnHurdle"
              type="danger"
              style={{
                width: "10em",
                float: "right",
                marginLeft: "20px",
                marginBottom: "20px",
              }}
              onClick={onDownload}
              icon={<DownloadOutlined />}
            >
              Download
            </Button>

            <Button
              className="btnHurdle"
              type="danger"
              icon={<PlusOutlined />}
              style={{
                width: "10em",
                float: "right",
                marginRight: "6px",
                marginBottom: "20px",
              }}
              onClick={(e) => openModal("Add")}
            >
              Add Hurdle
            </Button>
          </Col>
        </Row>

        <Table
          className="hurdle-table"
          rowClassName="row-hurdle"
          rowKey={(hurdle) => hurdle.ID}
          columns={hurdleColumns}
          dataSource={hurdleList}
          scroll={{ x: "max-content" }}
        />

        <HurdleModal
          hurdle={action === "Edit" ? hurdle : null}
          show={showModal}
          hurdles={hurdleList}
          onCancel={handleCancel}
          columns={hurdleColumns}
          action={action}
          visible={visible}
          className="modal-hurdle"
        />
      </Card>
    </div>
  );
};
export default HurdleList;
