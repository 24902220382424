import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editLuckyCode, addLuckyCode, allLuckyCodes } from "../../modules/luckycodes";
import { Input, Button, message, Modal, Form, DatePicker, Space, Divider, Select, Image, } from "antd/lib";
import { requiredTypes } from "../../helpers/helpers";
import {
    PlusOutlined,
    MinusCircleOutlined
} from "@ant-design/icons";
import '../../styles/LuckyCode.css'
import moment from "moment";
const WinTypeList = ["daily", "weekly", "total duration"]
const deleteOption = ["Yes", "No"]
const { RangePicker } = DatePicker;
const { Option } = Select;
const LuckyCodeModal = (props) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState(props.luckyCode);
    const [form] = Form.useForm();

    const [dates, setDates] = useState(null);
    const [hackValue, setHackValue] = useState(null);
    const [value, setValue] = useState(null);

    const [disabled, setDisabled] = useState(null);
    const [disableAddLevel, setDisableAddLevel] = useState(null);

    useEffect(() => {
        setDisabled(false);
        setDisableAddLevel(false);
        if (props.action !== "Add" && props.luckyCode) {
            setFormData(props.luckyCode)
            form.setFieldsValue(props.luckyCode);
            let current = moment().add(8, 'hours').utc()

            let running = props.programs.find(
                x => x.LuckyCodeID === props.luckyCode.ID
                    && x.IsProgramActive === 1
                    && current >= props.luckyCode.Date[0]
                    && current <= props.luckyCode.Date[1]
            );

            if (running) {
                setDisabled(true);
                setDisableAddLevel(true);
            }

            if (props.luckyCode.Combinations && props.luckyCode.Combinations.length === 10)
                setDisableAddLevel(true);
        }
        else {
            form.resetFields();
            setFormData(null);
        }

    }, [props.luckyCode]);

    const onFinish = (e) => {

        //sort
        let asc = (b, i, { [i - 1]: a }) => !i || a < b;
        let rewardCountList = e.Combinations.map(x => parseInt(x.RewardCount))

        if (!rewardCountList.every(asc)) {
            message.error("Error Levels. Reward Count not in order!");
        }
        else if (rewardCountList.length === 0) {
            message.error("Error Levels. Must have atleast 1 level!");
        }
        else if (rewardCountList.length < parseInt(e.UserWinCount)) {
            message.error("Error Levels. Win Count greater than # of levels!");
        }
        else {

            let lc = {
                Title: e.Title,
                StartDate: e.Date[0].format("YYYY-MM-DD HH:mm:ss"),
                EndDate: e.Date[1].format("YYYY-MM-DD HH:mm:ss"),
                UserWinCount: e.UserWinCount,
                WinType: e.WinType,
                Modal: e.Modal,
            }

            if(e.ForAchive === "Yes"){
                 lc.DeletedAt = moment().format('YYYY-MM-DD HH:mm:ss')
            }

            let lcc = []
            e.Combinations.map((obj, index) => {
                obj = {
                    LuckyCodeID: props.action === "Edit" ? props.luckyCode.ID : null,
                    Level: index + 1,
                    RewardCount: parseInt(obj.RewardCount),
                    RewardPoints: parseInt(obj.RewardPoints)
                }
                lcc.push(obj);
            });
            if (props.action === "Edit") {
                dispatch(editLuckyCode(props.luckyCode.ID, lc, lcc))
                    .then((response) => {
                        let data = response.payload.data;
                        if (data.success) {
                            message.success(`Lucky Code updated successfully!`, 5);
                            dispatch(allLuckyCodes());
                        } else {
                            message.error("Error on updating lucky code");
                        }
                    })
                    .catch((e) => {
                        message.error("Error on updating lucky code");
                    });
            }
            else {
                dispatch(addLuckyCode(lc, lcc))
                    .then((response) => {
                        let data = response.payload.data;
                        if (data.success) {
                            message.success(`Lucky Code added successfully!`, 5);
                            dispatch(allLuckyCodes());
                        } else {
                            message.error("Error on adding lucky code");
                        }
                    })
                    .catch((e) => {
                        message.error("Error on adding lucky code");
                    });
            }
        }
    };


    const disabledDate = (current) => {
        if (!dates) {
            return false;
        }

        const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;
        return !!tooEarly || !!tooLate;
    };

    const onOpenChange = (open) => {
        if (open) {
            setHackValue([null, null]);
            setDates([null, null]);
        } else {
            setHackValue(null);
        }
    };

    const luckyCodeModals = [
        '/modals/luckycode/lucky-code-modal-1.png',
        '/modals/luckycode/lucky-code-modal-2.png',
        '/modals/luckycode/lc-ch-sureprize-revised-modal.png',
        '/modals/luckycode/ch-masskara-modal.png',
      ];

    return (
        <Modal

            title={`${props.action} Lucky Code`}
            visible={props.visible}
            footer={null}
            onCancel={props.onCancel}
            className="modal-luckycode"
            width={750}
        >

            <Form
                id="luckycodeForm"
                form={form}
                onFinish={onFinish}
                hideRequiredMark
                initialValues={props.action !== "Add" && formData ? formData : null}
            >
                <Form.Item
                    name="Modal"
                    label="Lucky Code Modal"
                    >
                    <Select
                        placeholder="Choose Lucky Code Modal"
                        allowClear
                        optionLabelProp="label"
                    >
                        {luckyCodeModals
                        ? luckyCodeModals.map((modal,index) => {
                            return (
                                <Option value={modal} key={index} label={modal}>
                                    <Image
                                        width={50}
                                        src={modal}
                                    />
                                </Option>
                            );
                            })
                        : null}
                    </Select>
                    </Form.Item>

                <Form.Item
                    name="Title"
                    label="Title"
                    rules={requiredTypes.default}
                    style={{ margin: "0px 10em 24px 10em" }}
                >
                    <Input disabled={disabled} maxLength={50} placeholder="Title" />

                </Form.Item>
                <Form.Item name="Date" label="Date" rules={requiredTypes.default}
                    style={{ margin: "0px 10em 24px 10em" }}>
                    <RangePicker disabled={disabled}
                        style={{ width: "100%" }}
                        ranges={{
                            Today: [moment().startOf('day'), moment().endOf('day')],
                            'This Month': [moment().startOf('month'), moment().endOf('month')],
                        }}
                        value={hackValue || value}
                        disabledDate={(current) => {
                            return moment().add(-1, 'days') >= current ||
                                moment().add(1, 'months') <= current;
                        }}
                        onCalendarChange={(val) => setDates(val)}
                        onChange={(val) => setValue(val)}
                        onOpenChange={onOpenChange}
                        showTime={{
                            format: 'HH:mm:ss',
                        }}
                        format="YYYY-MM-DD HH:mm:ss"
                    />
                </Form.Item>
                <Form.Item
                    name="UserWinCount"
                    label="Win Count"
                    rules={requiredTypes.default}
                    style={{ margin: "0px 10em 24px 10em" }}
                >
                    <Input disabled={disabled}
                        type="number"
                        maxLenth="5"
                        placeholder="# of times user can win within the timeframe"
                        onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 5);
                        }}
                        onKeyDown={(evt) =>
                            (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                            evt.preventDefault()
                        } />

                </Form.Item>
                <Form.Item
                    name="WinType"
                    label="Win Type"
                    rules={requiredTypes.default}
                    style={{ margin: "0px 10em 24px 10em" }}>
                    <Select
                        allowClear
                        style={{ width: '100%' }}
                        disabled={disabled}
                    >
                        {WinTypeList.map((value) => {
                            return (<Option value={value} key={value} label={value}>
                                {value}
                            </Option>
                            );
                        })}
                    </Select>
                </Form.Item>
                {props.action !== "Add" ?
                    <Form.Item
                    name="ForAchive"
                    label="For Archive"
                    style={{ margin: "0px 10em 24px 10em" }}
                    >
                        <Select
                            allowClear
                            style={{ width: '100%' }}
                            disabled={disabled}
                            defaultValue="No"
                        >
                            {deleteOption.map((value) => {
                                return (<Option value={value} key={value} label={value}>
                                    {value}
                                </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    : null}

                <Divider>Levels</Divider>
                <Form.List
                    initialValues={props.action !== "Add" && formData ? formData.Combinations : null}
                    name="Combinations"
                    rules={[
                        {
                            validator: async (_, names) => {
                                if (names.length === 10) {
                                    setDisableAddLevel(true);
                                    return Promise.resolve()
                                }
                                else if (names.length < 10) {
                                    return Promise.resolve()
                                }
                                else
                                    return Promise.reject({ message: "Maximum of 10 levels only!" })

                            },
                        },
                    ]}

                >
                    {(fields, { add, remove }, { errors }) => (

                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    align="baseline"
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Form.Item
                                        name={[name, "RewardCount"]}
                                        rules={requiredTypes.default}
                                        label="Count"


                                    >
                                        <Input
                                            type="number"
                                            maxLenth="5"
                                            placeholder="Reward Count"
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value))
                                                    .toString()
                                                    .slice(0, 5);
                                            }}
                                            onKeyDown={(evt) =>
                                                (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                                                evt.preventDefault()
                                            }
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name={[name, "RewardPoints"]}
                                        label="Points"
                                        rules={requiredTypes.default}

                                    >
                                        <Input
                                            type="number"
                                            maxLength="5"
                                            placeholder="Reward Points"
                                            onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value))
                                                    .toString()
                                                    .slice(0, 5);
                                            }}
                                            onKeyDown={(evt) =>
                                                (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                                                evt.preventDefault()
                                            }
                                            disabled={disabled}
                                        />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}

                            <Form.Item >
                                <Form.ErrorList errors={errors} />
                                <Button disabled={disableAddLevel} type="dashed" onClick={() => add()} icon={<PlusOutlined />}
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        display: 'block',
                                        width: '30%'
                                    }}>
                                    Add Level
                                </Button>

                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Button disabled={disabled} type="danger" htmlType="submit" style={{ float: "right" }}>
                    SUBMIT
                </Button>
            </Form>


        </Modal >
    );
};
export default LuckyCodeModal;
