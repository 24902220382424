import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allPrograms, addProgram } from "../../modules/programs";
// import { allGames } from "../../modules/games";
import UserService from "../../services/UserService";
import {
  programIntColumns,
  programColumnCompare,
  compare,
  sort,
  filterByPropertyValue,
} from "../../helpers/helpers.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Form, Input, Select, Button, Space, Card, DatePicker } from "antd/lib";
import { allTerritories } from "../../modules/territories";
import { addProgramAuditLogs } from "../../modules/audit";
import moment from "moment";
import {
  sendExistMessage,
  sendSuccessMessage,
  sendFailedMessage,
  sendFailedRequiredMessage,
} from "../../helpers/messageHelper";
import { allHurdles } from "../../modules/hurdles";
const { Option } = Select;
const { RangePicker } = DatePicker;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 10,
    },
    sm: {
      span: 7,
    },
    md: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 10,
    },
    sm: {
      span: 15,
    },
    md: {
      span: 10,
    },
  },
};

const ProgramAdd = () => {
  const dispatch = useDispatch();
  const { territories } = useSelector((state) => state);
  const { programs } = useSelector((state) => state);
  // const { games } = useSelector((state) => state);
  const { hurdles } = useSelector((state) => state);
  const [regionList, setRegionList] = useState();
  const [territoryList, setTerritoryList] = useState();
  const [salesOfficeList, setSalesOfficeList] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [newProgram, setNewProgram] = useState();
  const [disabled, setDisabled] = useState(false);
  // const [gameOptions, setGameOptions] = useState();
  // const [gameDisabled, setGameDisabled] = useState(false);
  const [hurdleOptions, setHurdleOptions] = useState();
  const [hurdleDisabled, setHurdleDisabled] = useState(false);
  const onFinish = (values) => {
    setSubmitting(true);
    let valid = true;
    if (values.IsNational.toString() === "0") {
      if (
        values.Region.length === 0 &&
        values.SalesOffice.length === 0 &&
        values.Territory.length === 0
      ) {
        sendFailedRequiredMessage("Program", "Add");
        valid = false;
      }
    }
    if (valid) {
      values = prepareNewProgramValues(values);
      let exist = doesProgramExist(values);
      try {
        if (!exist) {
          dispatch(addProgram(values))
            .then((response) => {
              if (response.payload.data.success) {
                addAuditLogs(values, response.payload.data.id);
                sendSuccessMessage("Program", "Add");
                setNewProgram(values);
              } else {
                sendFailedMessage("Program", "Add");
              }
            })
            .catch((e) => {
              sendFailedMessage("Program", "Add");
              console.log(e);
            });
        } else {
          sendExistMessage("Program", "Add");
        }
      } catch (e) {
        sendFailedMessage("Program", "Add");
        console.log(e);
      }
    }
    setSubmitting(false);
  };

  const addAuditLogs = (values, id) => {
    var user = UserService.getUsername();
    var audit = {
      User: user,
      Action: "ADD",
      ProgramID: id,
      NewValue: JSON.stringify(values),
    };

    dispatch(addProgramAuditLogs(audit))
      .then((response) => {
        return response.payload.data.success;
      })
      .catch((e) => {
        return false;
      });
  };

  const doesProgramExist = (values) => {
    let exists = false;
    for (let index = 0; index <= programs.length; index++) {
      exists = compare(programs[index], values, programColumnCompare);
      if (exists) break;
    }
    return exists;
  };

  const prepareNewProgramValues = (values) => {
    values.Territory =
      values.Territory.length === 0 ? "NULL" : values.Territory.toString();
    values.Region =
      values.Region.length === 0 ? "NULL" : values.Region.toString();
    values.SalesOffice =
      values.SalesOffice.length === 0 ? "NULL" : values.SalesOffice.toString();
    values["SKU"] =
      values["SKU"] === "" || values["SKU"] === undefined
        ? "NULL"
        : values["SKU"];
    values.StartDate = values.Date[0].format("YYYY-MM-DD") + " 00:00:00";
    values.EndDate = values.Date[1].format("YYYY-MM-DD") + " 23:59:59";
    delete values.Date;

    if (values.IsProgramActive === "Yes" || values.IsProgramActive === "No")
      values.IsProgramActive = values.IsProgramActive === "Yes" ? 1 : 0;

    if (values.IsNational === "Yes" || values.IsNational === "No")
      values.IsNational = values.IsNational === "Yes" ? 1 : 0;

    if (values.HurdleEnabled === "Yes" || values.HurdleEnabled === "No")
      values.HurdleEnabled = values.HurdleEnabled === "Yes" ? 1 : 0;

    // if (values.GameEnabled === "Yes" || values.GameEnabled === "No")
    //   values.GameEnabled = values.GameEnabled === "Yes" ? 1 : 0;

	programIntColumns.map((col) => {
      values[col] = parseInt(values[col]);
    });

    // if (values.GameEnabled === 1) {
    //   values.GameStartDate =
    //     values.GameDate[0].format("YYYY-MM-DD") + " 00:00:00";
    //   values.GameEndDate =
    //     values.GameDate[1].format("YYYY-MM-DD") + " 23:59:59";
    // }
    // delete values.GameDate;



    return values;
  };
  const required = [
    {
      required: true,
      message: "Field must not be blank.",
    },
  ];

  // const gameRequired = [
  //   {
  //     required: gameDisabled ? false : true,
  //     message: "Field must not be blank.",
  //   },
  // ];

  const hurdleRequired = [{
    required: hurdleDisabled ? false : true,
    message: "Field must not be blank."
  }]

  useEffect(() => {
    dispatch(allPrograms());
    dispatch(allTerritories());
    // dispatch(allGames());
    dispatch(allHurdles());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(allPrograms());
  }, [newProgram]);

  useEffect(() => {
    setRegionList(sort([...new Set(territories.map((x) => x.Region))]));
    setSalesOfficeList(
      sort([...new Set(territories.map((x) => x.SalesOffice))])
    );
    setTerritoryList(
      sort([...new Set(territories.map((x) => x.TerritoryName))])
    );
  }, [territories]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    // let gamesEnabled = programs.filter((prog) => prog.GameEnabled === 1);
    // setGameOptions(
    //   games.filter(
    //     (game) => !gamesEnabled.map((x) => x.GameID).includes(game.GameID)
    //   )
    // );

  }, [programs]);
  // [programs, games]);
  useEffect(() => {
    let hurdleEnabled = programs.filter((prog) => prog.HurdleEnabled === 1);
    setHurdleOptions(
      hurdles.filter(
        (hurdle) => hurdle.IsActive === 1 && !hurdleEnabled.map((x) => x.HurdleID).includes(hurdle.ID)
      ))
      ;
  }, [programs, hurdles]);

  const onDataChange = (changedValues) => {
    const fieldName = Object.keys(changedValues)[0];
    const value = Object.values(changedValues)[0];

    if (fieldName === "IsNational") {
      if (value.toString() === "1") {
        setDisabled(true);
        form.setFieldsValue({ Region: [], Territory: [], SalesOffice: []});
      } else setDisabled(false);
    }
    if (fieldName === "Region") {
      let filteredList = filterByPropertyValue(territories, "Region", value);
      setSalesOfficeList(
        sort([...new Set(filteredList.map((x) => x.SalesOffice))])
      );
      setTerritoryList(
        sort([...new Set(filteredList.map((x) => x.TerritoryName))])
      );
      form.setFieldsValue({ Territory: [], SalesOffice: [] });
    } else if (fieldName === "SalesOffice") {
      let filteredList = filterByPropertyValue(
        territories,
        "SalesOffice",
        value
      );
      setTerritoryList(
        sort([...new Set(filteredList.map((x) => x.TerritoryName))])
      );
      form.setFieldsValue({ Territory: [] });
    }
    // else if (fieldName === "GameEnabled") {
    //   if (value.toString() === "1") setGameDisabled(false);
    //   else {
    //     form.setFieldsValue({ GameID: "", GameDate: [] });
    //     setGameDisabled(true);
    //   }
    // }
    else if (fieldName === "HurdleEnabled") {
      if (value.toString() === "1") setHurdleDisabled(false);
      else {
        form.setFieldsValue({ HurdleID: null });
        setHurdleDisabled(true);
      }
    }
  };

  const [form] = Form.useForm();

  return (
    <div className="row">
      <Card
        title={
          <Space size="large">
            <a href="/programs">
              <FontAwesomeIcon icon={faChevronCircleLeft} />
            </a>
            Add Program
          </Space>
        }
      >
        <Form
          form={form}
          name="form"
          {...formItemLayout}
          onFinish={onFinish}
          onValuesChange={onDataChange}
          labelAlign="right"
        >
          <Form.Item name="BrandName" label="Brand Name" rules={required}>
            <Input placeholder="Enter Brand Name to be displayed on the site" />
          </Form.Item>
          <Form.Item name="BrandCodes" label="Brand Codes" rules={required}>
            <Input placeholder="Enter BrandCode/s information from Promosys (comma-delimited)" />
          </Form.Item>
          {/* <Form.Item name="SKU" label="SKU">
            <Input placeholder="Separated by comma. Leave BLANK if none." />
          </Form.Item> */}
          <Form.Item name="IsNational" label="Is National" rules={required}>
            <Select
              placeholder="Should the program be on national level?"
              allowClear
            >
              <Option value="0">No</Option>
              <Option value="1">Yes</Option>
            </Select>
          </Form.Item>
          <Form.Item name="SKU" label="SKU">
            <Input placeholder="Separated by comma. Leave BLANK if none." />
          </Form.Item>
          <Form.Item name="IsProgramActive" label="Is Active" rules={required}>
            <Select placeholder="Should the program be active?" allowClear>
              <Option value="0">No</Option>
              <Option value="1">Yes</Option>
            </Select>
          </Form.Item>
          <Form.Item name="Region" label="Region">
            <Select
              mode="multiple"
              placeholder="Separated by comma. Leave BLANK if none"
              allowClear
              initialValue="NULL"
              disabled={disabled}
            >
              {regionList
                ? regionList.map((value) => {
                  return (
                    <Option value={value} key={value} label={value}>
                      {value}
                    </Option>
                  );
                })
                : null}
            </Select>
          </Form.Item>

          <Form.Item name="SalesOffice" label="Sales Office">
            <Select
              mode="multiple"
              placeholder="Separated by comma. Leave BLANK if none"
              allowClear
              disabled={disabled}
            >
              {salesOfficeList
                ? salesOfficeList.map((value) => {
                  return (
                    <Option value={value} key={value} label={value}>
                      {value}
                    </Option>
                  );
                })
                : null}
            </Select>
          </Form.Item>

          <Form.Item name="Territory" label="Territory">
            <Select
              mode="multiple"
              placeholder="Separated by comma. Leave BLANK if none"
              allowClear
              disabled={disabled}
            >
              {territoryList
                ? territoryList.map((value) => {
                  return (
                    <Option value={value} key={value} label={value}>
                      {value}
                    </Option>
                  );
                })
                : null}
            </Select>
          </Form.Item>

          <Form.Item name="Date" label="Date" rules={required}>
            <RangePicker
              style={{ width: "100%" }}
              disabledDate={(current) => {
                return current && current < moment().startOf("day");
              }}
            />
          </Form.Item>
          <Form.Item name="Points" label="Points" rules={required}>
            <Input
              type="number"
              maxLength="2"
              placeholder="Enter desired points for the program"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 2);
              }}
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                evt.preventDefault()
              }
            />
          </Form.Item>

          <Form.Item name="LimitType" label="Limit Type" rules={required}>
            <Select placeholder="Enter Limit Type" allowClear>
              <Option value="day">day</Option>
              <Option value="hour">hour</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="LimitFrequency"
            label="Limit Frequency"
            rules={required}
          >
            <Input
              type="number"
              placeholder="Enter frequency of the limit"
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                evt.preventDefault()
              }
            />
          </Form.Item>

          <Form.Item name="LimitCount" label="Limit Count" rules={required}>
            <Input
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 2);
              }}
              placeholder="Enter limit to how many valid codes are entered based on frequency"
              onKeyDown={(evt) =>
                (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                evt.preventDefault()
              }
            />
          </Form.Item>
          {/* <Form.Item
            name="GameEnabled"
            label="Is Game Enabled"
            rules={required}
          >
            <Select
              placeholder="Should a game be enabled for this program?"
              allowClear
            >
              <Option value="0">No</Option>
              <Option value="1">Yes</Option>
            </Select>
          </Form.Item>

          <Form.Item name="GameID" label="Game" rules={gameRequired}>
            <Select
              placeholder="Choose Game"
              allowClear
              optionLabelProp="label"
              disabled={gameDisabled}
            >
              {gameOptions
                ? gameOptions.map((game) => {
                  return (
                    <Option
                      value={game.GameID}
                      key={game.GameID}
                      label={game.GameID}
                    >
                      {game.Title}
                    </Option>
                  );
                })
                : null}
            </Select>
          </Form.Item>

          <Form.Item name="GameDate" label="GameDate" rules={gameRequired}>
            <RangePicker
              style={{ width: "100%" }}
              disabledDate={(current) => {
                return current && current < moment().startOf("day");
              }}
              disabled={gameDisabled}
            />
          </Form.Item> */}
          <Form.Item
            name="HurdleEnabled"
            label="Is Hurdle Enabled"
            rules={required}
          >
            <Select
              placeholder="Should a hurdle be enabled for this program?"
              allowClear
            >
              <Option value="0">No</Option>
              <Option value="1">Yes</Option>
            </Select>
          </Form.Item>


          <Form.Item name="HurdleID" label="Hurdle" rules={hurdleRequired}>
            <Select
              placeholder="Choose Hurdle"
              allowClear
              optionLabelProp="label"
              disabled={hurdleDisabled}
            >
              {hurdleOptions
                ? hurdleOptions.map((hurdle) => {
                  return (
                    <Option
                      value={hurdle.ID}
                      key={hurdle.ID}
                      label={hurdle.ID}
                    >
                      {hurdle.Title}
                    </Option>
                  );
                })
                : null}
            </Select>
          </Form.Item>
          <div>
            <Button
              type="primary"
              htmlType="submit"
              className="submit-button"
              disabled={submitting}
            >
              SUBMIT
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ProgramAdd;
