import { SUCCESS_SUFFIX } from "redux-axios-middleware";

import HttpService from "../../services/HttpService";

const LIST_OMNIVOUCHER_PROGRAMS = 'LIST_OMNIVOUCHER_PROGRAMS';
const ADD_OMNIVOUCHER_PROGRAMS = 'ADD_OMNIVOUCHER_PROGRAMS';
const EDIT_OMNIVOUCHER_PROGRAMS = 'EDIT_OMNIVOUCHER_PROGRAMS';

function fnGetAllOmnivoucherPrograms () {
  return {
    type: LIST_OMNIVOUCHER_PROGRAMS,
    payload: {
      request: {
        url: '/api/omnivoucher-programs',
        method: HttpService.HttpMethods.GET,
      },
    },
  };
}

function fnAddOmnivoucherProgram (omnivoucher_program) {
  return {
    type: ADD_OMNIVOUCHER_PROGRAMS,
    payload: {
      request: {
        url: '/api/omnivoucher-programs',
        method: HttpService.HttpMethods.POST,
        data: omnivoucher_program,
      },
    },
  }
}

function fnUpdateOmnivoucherProgram (id, omnivoucher) {
  return {
    type: EDIT_OMNIVOUCHER_PROGRAMS,
    payload: {
      request: {
        url: `/api/omnivoucher-programs/${id}`,
        method: HttpService.HttpMethods.PUT,
        data: omnivoucher
      },
    },
  }
}

function omnivoucherProgramsReducer (state = [], action) {

  // console.log('omnivoucherProgramsReducer()', state, ' ', action);

  switch (action.type) {
    case LIST_OMNIVOUCHER_PROGRAMS + SUCCESS_SUFFIX:
      return action.payload.data;

    default:
      return state;
  }
}

export {
  fnGetAllOmnivoucherPrograms,
  fnAddOmnivoucherProgram,
  fnUpdateOmnivoucherProgram,
};

export default omnivoucherProgramsReducer;
