import { SUCCESS_SUFFIX } from "redux-axios-middleware";

const LIST_PROGRAMTYPE = 'LIST_PROGRAMTYPE';

const programTypesReducer = (state = [], action) => {
  switch (action.type) {
    case LIST_PROGRAMTYPE + SUCCESS_SUFFIX:
      return action.payload.data;

    default:
      return state;
  }
};

export default programTypesReducer;

export const allProgramTypes = () => ({
  type: LIST_PROGRAMTYPE,
  payload: {
    request: {
      url: '/api/programTypes',
    },
  },
});
