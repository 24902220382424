import { applyMiddleware, compose, createStore } from "redux";
import axiosMiddleware from "redux-axios-middleware";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "../modules";
import HttpService from "./HttpService";

const setup = () => {
  const enhancers = [];
  const middleware = [
    thunk,
    axiosMiddleware(HttpService.getAxiosClient())
  ];

  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'quality') {
    enhancers.push(applyMiddleware(logger));
  }

  const composedEnhancers = compose;

  return createStore(rootReducer, {}, composedEnhancers(applyMiddleware(...middleware), ...enhancers));
};

const StoreService = {
  setup,
};

export default StoreService;
