import { SUCCESS_SUFFIX } from "redux-axios-middleware";

import HttpService from "../../services/HttpService";

const LIST_OMNIVOUCHER = 'LIST_OMNIVOUCHER';
const ADD_OMNIVOUCHER = 'ADD_OMNIVOUCHER';
// const EDIT_OMNIVOUCHER = 'EDIT_OMNIVOUCHER';

function fnGetAllOmnivouchers (filters, paginationState) {
  let pageSize = paginationState.pageSize;
  let currentPage = paginationState.currentPage;
  let offset = (currentPage - 1) * pageSize;
  return {
    type: LIST_OMNIVOUCHER,
    payload: {
      request: {
        url: `/api/omnivouchers?pageSize=${pageSize}&offset=${offset}&filters=${JSON.stringify(filters)}`,
        method: HttpService.HttpMethods.GET,
      },
    },
  };
}

function fnAddOmnivoucher (omnivouchers) {
  return {
    type: ADD_OMNIVOUCHER,
    payload: {
      request: {
        url: '/api/omnivouchers',
        method: HttpService.HttpMethods.POST,
        data: omnivouchers,
      },
    },
  };
}

/*function fnUpdateOmnivoucher (id, omnivoucher) {
  return {
    type: EDIT_OMNIVOUCHER,
    payload: {
      request: {
        url: `/api/omnivoucher-programs/${id}`,
        method: HttpService.HttpMethods.PUT,
        data: omnivoucher
      },
    },
  }
}*/

function omnivouchersReducer (state = [], action) {

  // console.log('omnivouchersReducer()', state, ' ', action);

  switch (action.type) {
    case LIST_OMNIVOUCHER + SUCCESS_SUFFIX:
      return action.payload.data;

    default:
      return state;
  }
}

export {
  fnGetAllOmnivouchers,
  fnAddOmnivoucher,
  // fnUpdateOmnivoucher,
};

export default omnivouchersReducer;
