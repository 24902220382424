import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  generateCodes,
  allRandomizerBatchCodes,
} from "../../modules/codeRandomizer";
import UserService from "../../services/UserService";
import { Input, Button, message, Modal, Form, Spin } from "antd/lib";
import { LoadingOutlined } from "@ant-design/icons";
import "../../styles/CodeRandomizer.css";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const CodeRandomizerModal = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = (e) => {
    setLoading(true);
    dispatch(
      generateCodes(
        e.prefix,
        parseInt(e.count),
        parseInt(e.length),
        UserService.getName()
      )
    )
      .then((response) => {
        if (response.payload.status === 200) {
          dispatch(allRandomizerBatchCodes());
          message.success(`Code Generation Started!`, 5);
          form.resetFields();
          props.onCancel();
        } else {
          message.error("Error on generating random codes");
        }
      })
      .catch((e) => {
        message.error("Error on generating random codes");
      });

    setLoading(false);
  };

  const clearForm = (e) => {
    e.preventDefault();
    form.resetFields();
  };

  return (
    <Modal
      title={`GENERATE NEW BATCH`}
      visible={props.visible}
      footer={null}
      onCancel={props.onCancel}
      className="modal-codeRandomizer"
      width={500}
    >
      <Spin
        spinning={loading}
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      >
        <Form
          {...layout}
          id="codeRandomizerForm"
          form={form}
          onFinish={onFinish}
          hideRequiredMark
        >
          <Form.Item
            name="prefix"
            label="Prefix"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Prefix is required!",
              },
              {
                validator(_, value) {
                  if (/\s/.test(value)) {
                    Promise.reject(new Error("Must have no whitespaces!"));
                  }
                  else if (value.length === 3) {
                    if (props.batchExists(value))
                      return Promise.reject(
                        new Error("Prefix already exists!")
                      );
                    else return Promise.resolve();
                  }
                  return Promise.reject(new Error("Must be 3 characters!"));
                },
              },
            ]}
          >
            <Input maxLength={3} />
          </Form.Item>
          <Form.Item name="length" label="Length" initialValue={10}>
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="count"
            label="Count"
            hasFeedback
            rules={[
              {
                required: true,
                message: "Count is required!",
              },
              {
                validator(_, value) {
                  if (parseInt(value) >= 1 && parseInt(value) <= 200000) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Value must be 1-200,000"));
                },
              },
            ]}
          >
            <Input
              maxLength={6}
              onKeyDown={(e) => {
                if (!e.key.match(/[0-9]/) && e.key !== "Backspace") {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Button onClick={clearForm} type="ghost">
            CLEAR
          </Button>
          <Button
            disabled={props.disableGenerate}
            type="danger"
            htmlType="submit"
            style={{ float: "right" }}
          >
            GENERATE
          </Button>
        </Form>
      </Spin>
    </Modal>
  );
};

export default CodeRandomizerModal;
