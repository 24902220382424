import UserService from "../services/UserService";
import { Button, Row, Col } from "antd/lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
const Welcome = () => (
  
  <Row className="welcome-container"
    type="flex"
    justify="center"
    align="middle"
    // style={{ minHeight: "70vh" }}
  >
  <Col>
  <h1>WELCOME</h1>
  <Button id="loginBtn" type="primary" onClick={() => UserService.doLogin()}>
        
       <span>Sign in <FontAwesomeIcon icon={faSignInAlt} /></span>
  </Button>
  </Col>
    
  </Row>
);

export default Welcome;
