import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { allPrograms, addProgram, editProgram } from "../../modules/programs";
// import { allGames } from "../../modules/games";
import UserService from "../../services/UserService";
import moment from "moment";
import { message } from "antd/lib";
import {
  Input,
  Select,
  Button,
  Switch,
  Modal,
  Form,
  DatePicker,
  TimePicker,
  Divider,
  Checkbox,
  Descriptions,
  Image,
} from "antd/lib";
import "../../styles/Program.css";
import {
  requiredTypes,
  sort,
  filterByPropertyValue,
  compare,
} from "../../helpers/helpers";
import {
  formItemLayout,
  fieldNameOptions,
  programColumnCompare,
  programIntColumns,
  emptyFields,
} from "../../helpers/program";
import {
  sendSuccessMessage,
  sendFailedMessage,
  sendExistMessage,
  sendFailedRequiredMessage,
  sendFailedRequiredContactFlexMessage,
} from "../../helpers/messageHelper";
import { addProgramAuditLogs } from "../../modules/audit";
import { set, toUpper } from "lodash";
const { Option } = Select;

const ProgramModal = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [salesOfficeList, setSalesOfficeList] = useState();
  const [regionList, setRegionList] = useState();
  const [territoryList, setTerritoryList] = useState();

  const [hurdleOptions, setHurdleOptions] = useState();
  const [hurdle, setHurdle] = useState();

  // const [gameOptions, setGameOptions] = useState();
  // const [game, setGame] = useState();

  const [luckyCodeOptions, setLuckyCodeOptions] = useState();
  const [luckyCode, setLuckyCode] = useState();

  const [programType, setProgramType] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [disabled, setDisabled] = useState({});
  const [dateTimeDisable, setDateTimeDisable] = useState(false);
  const [isHourlyProgram, setIsHourlyProgram] = useState(false);

  const [isAYO, setIsAYO] = useState(false);

  useEffect(() => {
    setRegionList(sort([...new Set(props.territories.map((x) => x.Region))]));
    setSalesOfficeList(
      sort([...new Set(props.territories.map((x) => x.SalesOffice))])
    );
    setTerritoryList(
      sort([...new Set(props.territories.map((x) => x.TerritoryName))])
    );
  }, [props.territories]);

  useEffect(() => {
    props.programtypes.forEach((x) => {
      setValuesOptions(x);
    });
  }, [props.programtypes]);

  useEffect(() => {
    if (props.visible && props.action === "Edit" && props.program) {
      props.programtypes.forEach((x) => {
        setValuesOptions(x);
      });
      setProgramType(props.program.ProgramType);
      manageOptions("Region", props.program.Region, true);
      manageOptions("SalesOffice", props.program.SalesOffice, true);
      manageOptions("Territory", props.program.Territory, true);

      let prog = {};

      moment().isAfter(moment.utc(props.program.StartDate)) &&
      moment().isBefore(moment.utc(props.program.EndDate)) &&
      (props.program.IsProgramActive === 1 || props.program.IsProgramActive)
        ? setDateTimeDisable(true)
        : setDateTimeDisable(false);

      props.programtypes.find((x) => x.ID === props.program.ProgramType)?.Type === "Hourly"
        ? setIsHourlyProgram(true)
        : setIsHourlyProgram(false);

      if (props.program.IsNational) {
        setDisabled(true);
        prog = {
          ...props.program,
          ...emptyFields,
          ContactFlex2:
            !props.program.ContactFlex2 || props.program.ContactFlex2 === "NULL"
              ? []
              : props.program.ContactFlex2.split(/[;,]/),
          ContactFlex3:
            !props.program.ContactFlex3 || props.program.ContactFlex3 === "NULL"
              ? []
              : props.program.ContactFlex3.split(/[;,]/),
          SKU: props.program.SKU === "NULL" ? [] : props.program.SKU?.split(/[;,]/).map(sku => sku.replace(/\s/g, '')),
        };
      } else {
        setDisabled(false);

        prog = {
          ...props.program,
          SKU: props.program.SKU === "NULL" ? [] : props.program.SKU?.split(/[;,]/).map(sku => sku.replace(/\s/g, '')),
          ContactFlex2:
            !props.program.ContactFlex2 || props.program.ContactFlex2 === "NULL"
              ? []
              : props.program.ContactFlex2.split(/[;,]/),
          ContactFlex3:
            !props.program.ContactFlex3 || props.program.ContactFlex3 === "NULL"
              ? []
              : props.program.ContactFlex3.split(/[;,]/),
          Region:
            props.program.Region === "NULL"
              ? []
              : props.program.Region?.split(/[;,]/).map(sku => sku.replace(/\s/g, '')),
          Territory:
            props.program.Territory === "NULL"
              ? []
              : props.program.Territory?.split(/[;,]/),
          SalesOffice:
            props.program.SalesOffice === "NULL"
              ? []
              : props.program.SalesOffice?.split(/[;,]/).map(sku => sku.replace(/\s/g, '')),
        };
      }
      form.setFieldsValue(prog);
    } else {
      resetForm();
    }
  }, [props.program]);

  useEffect(() => {
    let prog = {};
    if (props.action === "Add") {

        if (props.program?.IsNational) {
            setDisabled(true);
            prog = {
              ...props.program,
              ...emptyFields,

              SKU: props.program?.SKU === "NULL" ? [] : props.program?.SKU?.split(/[;,]/).map(sku => sku.replace(/\s/g, '')),
            };
          } else {
            setDisabled(false);

            prog = {
              ...props.program,
              SKU: props.program?.SKU === "NULL" ? [] : props.program?.SKU?.split(/[;,]/).map(sku => sku.replace(/\s/g, '')),
              ContactFlex2:
                !props.program?.ContactFlex2 || props.program.ContactFlex2 === "NULL"
                    ? []
                    : props.program?.ContactFlex2.split(/[;,]/),
              ContactFlex3:
                !props.program?.ContactFlex3 || props.program.ContactFlex3 === "NULL"
                    ? []
                    : props.program?.ContactFlex3.split(/[;,]/),
              Region:
                props.program?.Region === "NULL"
                    ? []
                    : props.program?.Region?.split(/[;,]/).map(sku => sku.replace(/\s/g, '')),
              Territory:
                props.program?.Territory === "NULL"
                    ? []
                    : props.program?.Territory?.split(/[;,]/),
              SalesOffice:
                props.program?.SalesOffice === "NULL"
                    ? []
                    : props.program?.SalesOffice?.split(/[;,]/).map(sku => sku.replace(/\s/g, '')),
                };

          }

      setDisabled(false);
      setDateTimeDisable(false);
      form.setFieldsValue(prog);
    }
  }, [props.action]);

  const resetForm = () => {
    form.resetFields();
    setHurdle({});
    setLuckyCode({});
    setProgramType("Default");
    setDisabled(false);
    // Added to auto close modal after successfull submit, but theres a bug when the model open twice (edit successful then add)
  };

  const manageOptions = (key, value, isInitial) => {
    setRegionList(sort([...new Set(props.territories.map((x) => x.Region))]));
    if (value?.length > 0) {
      let filteredList = filterByPropertyValue(props.territories, key, value);

      switch (key) {
        case "Region":
          setSalesOfficeList(
            sort([...new Set(filteredList.map((x) => x.SalesOffice))])
          );
          setTerritoryList(
            sort([...new Set(filteredList.map((x) => x.TerritoryName))])
          );
          if (!isInitial)
            form.setFieldsValue({ Territory: [], SalesOffice: [] });
          break;
        case "SalesOffice":
          setTerritoryList(
            sort([...new Set(filteredList.map((x) => x.TerritoryName))])
          );
          if (!isInitial) form.setFieldsValue({ Territory: [] });
          break;
      }
    }
  };
  const setValuesOptions = (programType) => {
    let list = props.programs.filter((x) => x.ProgramType === programType.ID);
    let availList = null;
    switch (programType.Type) {
      case "Hurdle":
        availList = props.hurdles.filter(
          (x) => x.IsActive === 1 && !list.map((y) => y.HurdleID).includes(x.ID)
        );
        setHurdleOptions(availList);
        if (props.action === "Edit")
          setHurdle(props.hurdles.find((x) => x.ID === props.program.HurdleID));
        else setHurdle({});
        break;
      case "Lucky Code":
        availList = props.luckycodes.filter(
          (x) => !list.map((y) => y.LuckyCodeID).includes(x.ID)
        );
        setLuckyCodeOptions(availList);
        if (props.action === "Edit")
          setLuckyCode(
            props.luckycodes.find((x) => x.ID === props.program.LuckyCodeID)
          );
        else setLuckyCode({});
        break;
    }
  };

  const doesProgramExist = (values) => {
    let exists = false;
    for (let index = 0; index <= props.programs.length; index++) {
      exists = compare(props.programs[index], values, programColumnCompare);
      if (exists) break;
    }
    return exists;
  };
  const callAdd = (values, audit) => {
    dispatch(addProgram(values))
      .then((response) => {
        if (response.payload.data.success) {
          audit = {
            ...audit,
            NewValue: JSON.stringify(values),
            ProgramID: response.payload.data.id,
          };
          dispatch(addProgramAuditLogs(audit));
          dispatch(allPrograms());
          sendSuccessMessage("Program", "Add");
          resetForm();
          props.onCancel();
        } else {
          sendFailedMessage("Program", "Add");
        }
      })
      .catch((e) => {
        sendFailedMessage("Program", "Add");
        console.log(e);
      });
  };
  const callUpdate = (values, audit) => {
    var newValues = {},
      oldValues = {};

    Object.keys(props.program).map(function (key) {
      if (programIntColumns.some((text) => text === key)) {
        if (parseInt(props.program[key]) !== parseInt(values[key])) {
          oldValues[key] = props.program[key];
          newValues[key] = values[key];
        }
      } else {
        if (props.program[key] !== values[key] && key !== "ProgramID") {
          oldValues[key] = props.program[key];
          newValues[key] = values[key];
        }
      }
    });
    if (
      Object.keys(oldValues).length > 0 &&
      Object.keys(newValues).length > 0
    ) {
      var audit = {
        ...audit,
        OldValue: JSON.stringify(oldValues),
        NewValue: JSON.stringify(newValues),
      };

      dispatch(editProgram(props.program.ProgramID, values))
        .then((response) => {
          if (response.payload?.data.success) {
            sendSuccessMessage("Program", "Edit");
            dispatch(addProgramAuditLogs(audit));
            dispatch(allPrograms());
          } else {
            sendFailedMessage("Program", "Edit");
          }
        })
        .catch((e) => {
          sendFailedMessage("Program", "Edit");
          console.log(e);
        });
    } else {
      sendExistMessage("Program", "Edit");
    }
  };
  const onFinish = (e) => {
    setSubmitting(true);
    let valid = true;

    if (
      !e.IsNational &&
      e.Region.length === 0 &&
      e.SalesOffice.length === 0 &&
      e.Territory.length === 0
    ) {
      sendFailedRequiredMessage("Program", props.action);
      valid = false;
    }

    if (valid) {
      e = {
        ...e,
        StartDate:
          props.programtypes.find((x) => x.ID === e.ProgramType).Type !==
          "Hourly"
            ? e.Date[0].format("YYYY-MM-DD") + " 00:00:00"
            : e.Date[0].format("YYYY-MM-DD HH:mm"),
        EndDate:
          props.programtypes.find((x) => x.ID === e.ProgramType).Type !==
          "Hourly"
            ? e.Date[1].format("YYYY-MM-DD") + " 23:59:59"
            : e.Date[1].format("YYYY-MM-DD HH:mm"),
        ContactFlex2: e.ContactFlex2
          ? e.ContactFlex2.toString() !== ""
            ? e.ContactFlex2.toString()
            : "NULL"
          : "NULL",
        ContactFlex3: e.ContactFlex3
          ? e.ContactFlex3.toString() !== ""
            ? e.ContactFlex3.toString()
            : "NULL"
          : "NULL",
        Region: e.Region.toString() !== "" ? e.Region.toString() : "NULL",
        Territory:
          e.Territory.toString() !== "" ? e.Territory.toString() : "NULL",
        SalesOffice:
          e.SalesOffice.toString() !== "" ? e.SalesOffice.toString() : "NULL",
        SKU: e.SKU
          ? e.SKU.toString() !== ""
            ? e.SKU.toString()
            : "NULL"
          : "NULL",
        LimitCount: parseInt(e.LimitCount),
        LimitFrequency: parseInt(e.LimitFrequency),
        Points: parseInt(e.Points),
        HurdleID: parseInt(e.HurdleID),
        LuckyCodeID: parseInt(e.LuckyCodeID),
        IsProgramActive: e.IsProgramActive ? e.IsProgramActive : 0,
        IsNational: e.IsNational ? e.IsNational : 0,
      };
      delete e.Date;

      // Check if theres already active program
      const existingActive = props.programs
        .filter((program) => program.ProgramID !== e.ProgramID)
        .find(
          (program) => program.IsProgramActive || program.IsProgramActive === 1
        );

      if (
        existingActive &&
        isHourlyProgram &&
        (e.IsProgramActive || e.IsProgramActive === 1)
      ) {
        message.error({
          content: `UPC Hourly Program cannot be save. Deactivate all active UPC program to proceed.`,
          duration: 5,
        });
      } else if (doesProgramExist(e)) {
        sendExistMessage("Program", props.action);
      } else {
        var audit = {
          User: UserService.getName(),
          Action: toUpper(props.action),
          ProgramID: props.action === "Edit" ? props.program.ProgramID : null,
        };

        if (props.action === "Edit") callUpdate(e, audit);
        else callAdd(e, audit);
      }
    }

    setSubmitting(false);
  };

  const onDataChange = (changedValues) => {
    const fieldName = Object.keys(changedValues)[0];
    const value = Object.values(changedValues)[0];
    switch (fieldName) {
      case "ProgramType":
        setProgramType(value);
        form.setFieldsValue({ HurdleID: null, LuckyCodeID: null });

        if (value === props.programtypes.find((x) => x.Type === "Hourly")?.ID) {
          setIsHourlyProgram(true);
          form.setFieldsValue({ LimitType: "hour" });
        } else {
          setIsHourlyProgram(false);
          form.setFieldsValue({ LimitType: "day" });
        }

        break;
      case "HurdleID":
        setHurdle(hurdleOptions.find((x) => x.ID === value));
        break;
      case "LuckyCodeID":
        setLuckyCode(luckyCodeOptions.find((x) => x.ID === value));
        break;
      case "IsNational":
        if (value === true) {
          setDisabled(true);
          form.setFieldsValue({
            Region: [],
            Territory: [],
            SalesOffice: [],
            // SKU: [],
          });
        } else setDisabled(false);
        break;
      case "SKU":
        let skuValue = value.toString().split(/[;,]/).map(sku => sku.replace(/\s/g, ''))
        form.setFieldsValue({ SKU: skuValue })
      case "IsAYO":
        if (value === true) {
          setIsAYO(true);
          // form.setFieldsValue({
          //   ContactFlex2: [],
          //   ContactFlex3: [],
          // });
        } else setIsAYO(false);
        break;
      case "ContactFlex2" || "ContactFlex3":
        if (value) setIsAYO(false);
        break;
    }

    if (fieldNameOptions.includes(fieldName)) {
      manageOptions(fieldName, value, false);
    }
  };

  const getDisabledHours = () => {
    var hours = [];
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const programModals = [
    '/modals/program-modal.png',
    '/modals/lucky-code-modal.png',
    '/modals/chesterfield-modal.png',
    '/modals/chesterfield-surprise-modal.png',
    '/modals/chesterfield-jp-modal.png',
    '/modals/ch-sureprize-revised-modal.png',
    '/modals/ch-masskara-modal.png',
    '/modals/mb-standard-modal.jpg',
  ];

  return (
    <Modal
      title={
        props.action === "Edit"
          ? `${props.action} Program ID: ${props.program.ProgramID}`
          : "Add New Program"
      }
      visible={props.visible}
      footer={null}
      onCancel={props.onCancel}
      width={800}
      style={{ height: "calc(100vh - 120px)" }}
      bodyStyle={{ overflowY: "scroll" }}
    >
      <Form
        preserve={false}
        id="programForm"
        hideRequiredMark
        name="form"
        form={form}
        onFinish={onFinish}
        {...formItemLayout}
        onValuesChange={onDataChange}
        labelAlign="right"
      >
        <Form.Item hidden name="ProgramID" label="ProgramID">
          <Input placeholder="ID" />
        </Form.Item>

        <Form.Item
          name="Modal"
          label="Program Modal"
        >
          <Select
            placeholder="Choose Program Modal"
            allowClear
            optionLabelProp="label"
          >
            {programModals
              ? programModals.map((modal,index) => {
                  return (
                    <Option value={modal} key={index} label={modal}>
                        <Image
                            width={50}
                            src={modal}
                          />
                    </Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>

        <Divider>Type</Divider>
        <Form.Item
          name="ProgramType"
          label="Type"
          required={requiredTypes.default}
        >
          <Select
            placeholder="Choose Program Type"
            allowClear
            optionLabelProp="label"
          >
            {props.programtypes
              ? props.programtypes.map((x) => {
                  return (
                    <Option value={x.ID} key={x.ID} label={x.Type}>
                      {x.Type}
                    </Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>
        {props.visible &&
        programType ===
          props.programtypes.find((x) => x.Type === "Hurdle")?.ID ? (
          <>
            <Form.Item
              name="HurdleID"
              label="Hurdle"
              rules={requiredTypes.default}
            >
              <Select
                placeholder="Choose Hurdle"
                allowClear
                optionLabelProp="label"
              >
                {hurdleOptions
                  ? hurdleOptions.map((item) => {
                      return (
                        <Option
                          value={item.ID}
                          key={item.ID}
                          label={item.Title}
                        >
                          {item.Title}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
            <Descriptions size="small" title="Hurdle" column={1} bordered>
              {hurdle &&
                Object.keys(hurdle).map((x) => {
                  return (
                    <Descriptions.Item label={x}>
                      {x.includes("Date")
                        ? moment.utc(hurdle[x]).format("YYYY-MM-DD HH:mm:ss")
                        : hurdle[x]}
                    </Descriptions.Item>
                  );
                })}
            </Descriptions>
          </>
        ) : null}
        {props.visible &&
        programType ===
          props.programtypes.find((x) => x.Type === "Lucky Code").ID ? (
          <>
            <Form.Item
              name="LuckyCodeID"
              label="LuckyCode"
              rules={requiredTypes.default}
            >
              <Select
                placeholder="Choose Lucky Code"
                allowClear
                optionLabelProp="label"
              >
                {luckyCodeOptions
                  ? luckyCodeOptions.map((item) => {
                      return (
                        <Option
                          value={item.ID}
                          key={item.ID}
                          label={item.Title}
                        >
                          {item.Title}
                        </Option>
                      );
                    })
                  : null}
              </Select>
            </Form.Item>
            <Descriptions size="small" title="Lucky Code" column={1} bordered>
              {luckyCode &&
                Object.keys(luckyCode).map((x) => {
                  if (x !== "Combinations")
                    return (
                      <Descriptions.Item label={x}>
                        {x.includes("Date")
                          ? moment
                              .utc(luckyCode[x])
                              .format("YYYY-MM-DD HH:mm:ss")
                          : luckyCode[x]}
                      </Descriptions.Item>
                    );
                })}
            </Descriptions>
          </>
        ) : null}
        <Divider>Brand Details</Divider>
        <Form.Item
          name="BrandName"
          label="Brand Name"
          rules={requiredTypes.default}
        >
          <Input placeholder="Enter Brand Name to be displayed on the site" />
        </Form.Item>
        <Form.Item
          name="BrandCodes"
          label="Brand Codes"
          rules={requiredTypes.default}
        >
          <Input placeholder="Enter BrandCode/s information from Promosys (comma-delimited)" />
        </Form.Item>
        <Divider>Status &amp; Schedule</Divider>
        <Form.Item
          name="IsProgramActive"
          label="Enabled"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item name="Date" label="Date" rules={requiredTypes.default}>
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            disabledDate={(current) =>
              current && current < moment().startOf("day")
            }
            showTime={
              isHourlyProgram && {
                format: "HH:mm",
                hideDisabledOptions: true,
              }
            }
            disabledHours={getDisabledHours}
            disabledMinutes={getDisabledMinutes}
            disabled={dateTimeDisable}
          />
        </Form.Item>
        <Form.Item
          name="LimitType"
          label="Limit Type"
          rules={requiredTypes.default}
        >
          <Select
            placeholder="Enter Limit Type"
            allowClear
            disabled={isHourlyProgram}
          >
            <Option value="day">day</Option>
            <Option value="hour">hour</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="LimitFrequency"
          label="Limit Frequency"
          rules={requiredTypes.default}
        >
          <Input
            type="number"
            placeholder="Enter frequency of the limit"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
              evt.preventDefault()
            }
          />
        </Form.Item>
        <Form.Item
          name="LimitCount"
          label="Limit Count"
          rules={requiredTypes.default}
        >
          <Input
            type="number"
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 2);
            }}
            placeholder="Enter limit to how many valid codes are entered based on frequency"
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
              evt.preventDefault()
            }
          />
        </Form.Item>
        <Form.Item name="Points" label="Points" rules={requiredTypes.default}>
          <Input
            type="number"
            maxLength="2"
            placeholder="Enter desired points for the program"
            onInput={(e) => {
              e.target.value = Math.max(0, parseInt(e.target.value))
                .toString()
                .slice(0, 2);
            }}
            onKeyDown={(evt) =>
              (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
              evt.preventDefault()
            }
          />
        </Form.Item>
        <Divider>Target</Divider>
        <Form.Item
          name="IsNational"
          valuePropName="checked"
          label="Is National"
        >
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item
          name="Region"
          label="Region"
          rules={!disabled ? requiredTypes.default : ""}
        >
          <Select
            mode="multiple"
            placeholder="Separated by comma. Leave BLANK if none"
            allowClear
            optionLabelProp="label"
            disabled={disabled}
          >
            {regionList
              ? regionList.map((region) => {
                  return (
                    <Option value={region} key={region} label={region}>
                      {region}
                    </Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>
        <Form.Item name="SalesOffice" label="Sales Office">
          <Select
            mode="multiple"
            placeholder="Separated by comma. Leave BLANK if none"
            allowClear
            disabled={disabled}
          >
            {salesOfficeList
              ? salesOfficeList.map((value) => {
                  return (
                    <Option value={value} key={value} label={value}>
                      {value}
                    </Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>
        <Form.Item name="Territory" label="Territory">
          <Select
            mode="multiple"
            placeholder="Separated by comma. Leave BLANK if none"
            allowClear
            optionLabelProp="label"
            disabled={disabled}
          >
            {territoryList
              ? territoryList.map((territory) => {
                  return (
                    <Option value={territory} key={territory} label={territory}>
                      {territory}
                    </Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>
        <Form.Item name="SKU" label="SKU">
          <Select
            mode="tags"
            // disabled={disabled}
            placeholder="Separated by comma. Leave BLANK if none"
          />
        </Form.Item>
         {/* HIDE FOR PRODUCTION DEPLOYMENT */}
        <Divider>AYO</Divider>
        <Form.Item name="IsAYO" valuePropName="checked" label="Is AYO">
          <Checkbox></Checkbox>
        </Form.Item>
        <Form.Item name="ContactFlex2" label="Contact Flex 2">
          <Select mode="tags" placeholder="Enter Contact Flex 2" />
        </Form.Item>
        <Form.Item name="ContactFlex3" label="Contact Flex 3">
          <Select mode="tags" placeholder="Enter Contact Flex 3" />
        </Form.Item>
        <div>
          <Button
            type="primary"
            htmlType="submit"
            className="submit-button"
            disabled={submitting}
          >
            SUBMIT
          </Button>
        </div>
      </Form>
    </Modal>
  );
};
export default ProgramModal;
