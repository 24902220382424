import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allTerritories,
  addTerritory,
  editTerritory,
} from "../../modules/territories";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import {
  Table,
  Card,
  Input,
  Divider,
  Space,
  Button,
  message,
  Row,
  Col,
  Modal,
  Form,
} from "antd/lib";
import {
  DownloadOutlined,
  UploadOutlined,
  PlusOutlined,
  DeleteFilled,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import {
  filterByMultiplePropertyContainsValue,
  filterByPropertyValue,
  sort,
  sortBy,
} from "../../helpers/helpers";
import * as XLSX from "xlsx";
const { confirm } = Modal;
const { Search } = Input;
const importExcel = (file, items) => {
  const fileReader = new FileReader();
  let rows = [];
  fileReader.onload = (event) => {
    try {
      const { result } = event.target;
      const workbook = XLSX.read(result, { type: "binary" });
      for (const Sheet in workbook.Sheets) {
        XLSX.utils.sheet_to_row_object_array(workbook.Sheets[Sheet]);
        if (workbook.Sheets.hasOwnProperty(Sheet)) {
          let data = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[Sheet]
          );
          data.forEach((x) => {
            if (
              x.TerritoryCode &&
              x.TerritoryName &&
              x.Region &&
              x.SalesOffice
            ) {
              rows.push({
                TerritoryCode: x.TerritoryCode,
                TerritoryName: x.TerritoryName,
                Region: x.Region,
                SalesOffice: x.SalesOffice,
              });
            }
          });
          items(rows);
        }
      }
    } catch (e) {
      console.log(e);
      message.error("File Type is incorrect!");
    }
  };
  fileReader.readAsBinaryString(file);
};

const TerritoryList = () => {
  const dispatch = useDispatch();
  const { territories } = useSelector((state) => state);
  const [territoryList, setTerritoryList] = useState();
  const [visibleAddModal, setVisibleAddModal] = useState(false);
  const [visibleEditModal, setVisibleEditModal] = useState(false);
  const [territory, setTerritory] = useState();
  const [territoryCodesToDelete, setTerritoryCodesToDelete] = useState();
  const [form] = Form.useForm();
  const territoryColumns = [
    {
      title: "EDIT",
      key: "operation",
      width: 30,
      fixed: "left",
      render: (text, record, index) => (
        <a
          onClick={(e) => {
            setTerritory(record);
            showEditModal();
          }}
        >
          <FontAwesomeIcon icon={faEdit} />
        </a>
      ),
    },
    {
      title: "CODE",
      width: 120,
      dataIndex: "TerritoryCode",
      key: "TerritoryCode",
      name: "Territory Code",
      maxLength: 20,
    },
    {
      title: "NAME",
      width: 150,
      dataIndex: "TerritoryName",
      key: "TerritoryName",
      name: "Territory Name",
      maxLength: 100,
    },
    {
      title: "REGION",
      width: 120,
      dataIndex: "Region",
      key: "Region",
      name: "Region",
      maxLength: 10,
    },
    {
      title: "SALES OFFICE",
      width: 120,
      dataIndex: "SalesOffice",
      key: "SalesOffice",
      name: "Sales Office",
      maxLength: 10,
    },
  ];

  useEffect(() => {
    dispatch(allTerritories());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setTerritoryList(sortBy(territories, "TerritoryName"));
  }, [territories]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    form.setFieldsValue(territory);
  }, [form, territory]);

  const showAddModal = () => {
    setVisibleAddModal(true);
  };

  const handleAddCancel = () => {
    setVisibleAddModal(false);
  };

  const showEditModal = (record) => {
    setVisibleEditModal(true);
  };

  const handleEditCancel = () => {
    setVisibleEditModal(false);
  };
  const onImportExcel = (event) => {
    const { files } = event.target;
    if (files.length === 1) {
      importExcel(files[0], (rows) => {
        if (rows.length > 0) {
          dispatch(addTerritory(rows))
            .then((response) => {
              let data = response.payload.data;
              if (data.success) {
                message.success(
                  `${data.count}/${rows.length} territories added successfully! (Duplicates removed)`,
                  10
                );
                dispatch(allTerritories());
              } else {
                if (data.count === 0) {
                  message.error("Upload failed! Territories already exist!");
                } else message.error("Error on uploading territories.");
              }
            })
            .catch((e) => {
              message.error("Error on uploading territories.");
            });
        }else{
          message.error("Upload failed! Territory columns does not exist in the file",10);
        }
      });
    }
    event.target.value = null;
  };
  const onSearch = (e) => {
    if (e === "") setTerritoryList(sortBy(territories, "TerritoryName"));
    else
      setTerritoryList(
        sortBy(
          filterByMultiplePropertyContainsValue(
            territories,
            ["TerritoryName", "Region", "SalesOffice", "TerritoryCode"],
            e
          ),
          "TerritoryName"
        )
      );
  };
  const onAddFinish = (e) => {
    let terri = [];
    terri.push(e);
    dispatch(addTerritory(terri))
      .then((response) => {
        let data = response.payload.data;
        if (data.success) {
          message.success(`Territory added successfully!`, 5);
          dispatch(allTerritories());
        } else {
          if (data.count === 0) message.error("Territory already exists!");
          else message.error("Error on adding territory");
        }
      })
      .catch((e) => {
        message.error("Error on adding territory");
      });
    showAddModal(false);
  };

  const onEditFinish = (e) => {
    let same = true;
    for (var i = 2; i < territoryColumns.length; i++) {
      var key = territoryColumns[i].key;
      if (e[key] !== territory[key]) {
        same = false;
      }
    }

    if (!same) {
      dispatch(editTerritory(territory.TerritoryCode, e))
        .then((response) => {
          let data = response.payload.data;
          if (data.success) {
            message.success(`Territory updated successfully!`, 5);
            dispatch(allTerritories());
          } else {
            // if (data.count === 0) message.error("Territory already exists!");
            // else
            message.error("Error on updating territory");
          }
        })
        .catch((e) => {
          message.error("Error on updating territory");
        });
      showEditModal(false);
    } else {
      message.error("No changes performed!");
    }
  };

  const onDownload = (e) => {
    const ws = XLSX.utils.json_to_sheet(territoryList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Territories");
    XLSX.writeFile(wb, `Territories.xlsx`);
    if (territoryList.length !== territories.length)
      message.success("Filtered Territories downloaded!");
    else {
      message.success("All Territories downloaded!");
    }
  };

  // const onDelete = (e) => {
  //   if (territoryCodesToDelete) {
  //     dispatch(deleteTerritory(territoryCodesToDelete))
  //       .then((response) => {
  //         let data = response.payload.data;
  //         if (data.success) {
  //           message.success(`Selected territories deleted!`, 5);
  //           dispatch(allTerritories());
  //         } else {
  //           message.error("Error on deleting territory");
  //         }
  //       })
  //       .catch((e) => {
  //         message.error("Error on deleting territory");
  //       });
  //     setTerritoryCodesToDelete([]);
  //   }
  // };
  const required = [
    {
      required: true,
      message: "Field must not be blank.",
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setTerritoryCodesToDelete(selectedRowKeys);
    },
  };

  // const showConfirmDelete = () => {
  //   if (territoryCodesToDelete) {
  //     confirm({
  //       title: "Do you want to delete these items?",
  //       icon: <ExclamationCircleOutlined />,
  //       onOk() {
  //         onDelete();
  //       },
  //       onCancel() {},
  //     });
  //   } else {
  //     message.error("Select a territory to delete!");
  //   }
  // };

  return (
    <div className="row">
      <Card title="TERRITORY LIST">
        <Row>
          <Col flex={2}>
            <Search
              style={{ width: "30em" }}
              placeholder="Search Territory"
              allowClear
              size="large"
              onSearch={onSearch}
            />
          </Col>
          <Col flex={3}>
            <Button
              className="btnTerritory"
              type="danger"
              style={{ width: "10em", float: "right", marginLeft: "20px" }}
              onClick={onDownload}
              icon={<DownloadOutlined />}
            >
              Download
            </Button>

            {/* <Button
              className="btnTerritory"
              type="danger"
              style={{ width: "12em", float: "right", marginLeft: "10px" }}
              onClick={showConfirmDelete}
              icon={<DeleteFilled />}
            >
              Delete Selected
            </Button> */}

            <Button
              className="btnTerritory"
              type="danger"
              icon={<UploadOutlined />}
              style={{ width: "9.5em", float: "right", marginRight: "10px" }}
            >
              <label htmlFor="filePicker" style={{}}>
                {" "}
                Bulk Upload
              </label>
              <input
                id="filePicker"
                style={{ visibility: "hidden" }}
                type={"file"}
                onChange={onImportExcel}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              ></input>
            </Button>
            <Button
              className="btnTerritory"
              type="danger"
              icon={<PlusOutlined />}
              style={{ width: "10em", float: "right", marginRight: "6px" }}
              onClick={showAddModal}
            >
              Add Territory
            </Button>
          </Col>
        </Row>

        <Divider />
        <Table
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          className="territory-table"
          rowClassName="row-territory"
          rowKey={(territory) => territory.TerritoryCode}
          columns={territoryColumns}
          dataSource={territoryList}
          scroll={{ x: "max-content" }}
        />

        <Modal
          title="Add Territory"
          visible={visibleAddModal}
          footer={null}
          onCancel={handleAddCancel}
          className="modal-territory"
        >
          <Form
            id="addTerritoryForm"
            onFinish={onAddFinish}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
          >
            {territoryColumns.map((x) => {
              if (x.key !== "operation")
                return (
                  <Form.Item
                    name={x.key}
                    label={x.name}
                    rules={required}
                    style={{ marginTop: "0px" }}
                  >
                    <Input maxLength={x.maxLength} />
                  </Form.Item>
                );
            })}
            <Button type="danger" htmlType="submit" style={{ float: "right" }}>
              SUBMIT
            </Button>
          </Form>
        </Modal>

        <Modal
          title="Edit Territory"
          visible={visibleEditModal}
          footer={null}
          onCancel={handleEditCancel}
          className="modal-territory-edit"
        >
          <Form
            id="editTerritoryForm"
            form={form}
            onFinish={onEditFinish}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={territory}
          >
            {territory
              ? territoryColumns.map((x) => {
                  if (x.key !== "operation" && x.key !== "TerritoryCode")
                    return (
                      <Form.Item name={x.key} label={x.name} rules={required}>
                        <Input maxLength={x.maxLength} />
                      </Form.Item>
                    );
                })
              : null}
            <Button type="danger" htmlType="submit" style={{ float: "right" }}>
              SUBMIT
            </Button>
          </Form>
        </Modal>
      </Card>
    </div>
  );
};

export default TerritoryList;
