import moment from "moment";
import _ from "lodash";

export var fieldNameOptions = [
    "Region",
    "Territory",
    "SalesOffice"
];

export var emptyFields = {
    Region: [],
    Territory: [],
    SalesOffice: []
};

export var omnivoucherColumns = [

    /*{
        title: "ID",
        width: 50,
        fixed: "left",
        dataIndex: "VoucherID",
        key: "VoucherID",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.VoucherID - b.VoucherID,
    },*/
    {
        title: "VOUCHER CODE",
        width: 120,
        fixed: "left",
        dataIndex: "VoucherCode",
        key: "VoucherCode",
    },
    {
        title: "CONTROL NUMBER",
        width: 120,
        fixed: "left",
        dataIndex: "ControlNumber",
        key: "ControlNumber",
    },
    {
        title: "VOUCHER STATUS",
        width: 120,
        fixed: "left",
        dataIndex: "Status",
        key: "Status",
        align: "left",
    },
    {
        title: "REDEEMER",
        width: 120,
        dataIndex: "UserID",
        key: "UserID",
        align: "left",
    },
    {
        title: "REDEMPTION DATE",
        width: 120,
        dataIndex: "RedemptionDate",
        key: "RedemptionDate",
        align: "left",
    },
    {
        title: "REDEEMER CONTACT ID",
        width: 120,
        dataIndex: "ContactID",
        key: "ContactID",
        align: "left",
    },
    {
        title: "PROGRAM DESCRIPTION",
        width: 120,
        dataIndex: "Description",
        key: "Description",
        align: "left",
    },
    {
        title: "PROGRAM STATUS",
        width: 120,
        dataIndex: "IsActive",
        key: "IsActive",
        render: (value) => (value ? "Yes" : "No"),
        align: "left",
    },
    {
        title: "BRAND NAME",
        width: 120,
        dataIndex: "BrandName",
        key: "BrandName",
        align: "left",
    },
    {
        title: "START DATE",
        width: 120,
        dataIndex: "StartDate",
        key: "StartDate",
        sorter: (a, b) => moment(a.StartDate).unix() - moment(b.StartDate).unix(),
        align: "left",
    },
    {
        title: "END DATE",
        width: 120,
        dataIndex: "EndDate",
        key: "EndDate",
        sorter: (a, b) => moment(a.EndDate).unix() - moment(b.EndDate).unix(),
        align: "left",
    },
    {
        title: "POINTS",
        width: 120,
        dataIndex: "Points",
        key: "Points",
        align: "left",
    },
    {
        title: "STATUS POINTS",
        width: 120,
        dataIndex: "StatusPoints",
        key: "StatusPoints",
        align: "left",
    },
    {
        title: "NATIONAL",
        width: 120,
        dataIndex: "IsNational",
        key: "IsNational",
        render: (value) => (value ? "Yes" : "No"),
        align: "left",
    },
    {
        title: "REGION",
        width: 120,
        dataIndex: "Region",
        key: "Region",
        render: (value) => (value === "NULL" ? "" : value),
    },
    {
        title: "SALES OFFICE",
        width: 120,
        dataIndex: "SalesOffice",
        key: "SalesOffice",
        render: (value) => (value === "NULL" ? "" : value),
    },
    {
        title: "TERRITORY",
        width: 120,
        dataIndex: "Territory",
        key: "Territory",
        render: (value) => (value === "NULL" ? "" : value),
    },

    {
        title: "DAILY LIMIT",
        width: 120,
        dataIndex: "DailyLimit",
        key: "DailyLimit",
        align: "left",
    },
];

/*
export var omnivoucherAuditColumns = [
    {
        title: "TIMESTAMP",
        width: 50,
        dataIndex: "Timestamp",
        key: "Timestamp",
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
        title: "USER",
        width: 50,
        dataIndex: "User",
        key: "User",
        filterSearch: true,
    },
    {
        title: "ACTION",
        width: 50,
        dataIndex: "Action",
        key: "Action"
    },
    {
        title: "ID",
        width: 40,
        dataIndex: "VoucherID",
        key: "VoucherID"
    },
    {
        title: "OLD VALUE",
        width: 100,
        dataIndex: "OldValue",
        key: "OldValue",
        render: (value) => {
            if (value === null) {
                return "";
            }

            let val = JSON.parse(value);

            return Object.keys(val).map((e) => {
                return (
                    <p className="audit-para">
                        {e} : {val[ e ]}
                    </p>
                );
            });
        },
    },
    {
        title: "NEW VALUE",
        width: 100,
        dataIndex: "NewValue",
        key: "NewValue",
        render: (value) => {
            let val = JSON.parse(value);
            let obj = Object.keys(val).map((e) => {
                return (
                    <p className="audit-para">
                        {e} : {val[ e ]}
                    </p>
                );
            });
            return obj;
        },
    },
];
*/

export var omnivoucherIntColumns = [
    "IsActive",
    "Points",
    "StatusPoints",
    "IsNational",
    "DailyLimit",
];

export const filterSearchResult = (objs, values) => {

    var keys = Object.keys(values);

    var tempList = objs.omnivouchers;

    _.forEach(keys, (key) => {
        if (
            key !== "StartDate" &&
            key !== "EndDate" &&
            key !== "IsNational" &&
            key !== "IsActive"
        ) {
            // filter each category submitted
            tempList = _.filter(tempList, function (item) {

                if (
                    item[key] !== null && item[key].includes(values[key])
                ) {
                    return item;
                }
            });
        }
        else if (key === "StartDate" || key === "EndDate") {

            tempList = _.filter(tempList, function (item) {

                let timestamp = new Date(item.Timestamp);
                let start = new Date(values["StartDate"]);
                let end = new Date(values["EndDate"]);

                if (timestamp >= start && timestamp <= end) {
                    return item;
                }
            });
        }
        else if (key === "IsNational" || key === "IsActive") {

            tempList = _.filter(tempList, function (item) {
                if (!!item[key] === values[key]) return item;
            });
        }
    });

    return tempList;
};

export const fortmatExportList = (list, types) => {

    let formattedList = list.map((voucher) => ({
        // VoucherID: voucher.VoucherID,
        VoucherCode: voucher.VoucherCode,
        ControlNumber: voucher.ControlNumber,
        VoucherStatus: voucher.Status,
        Redeemer: voucher.UserID,
        RedemptionDate:voucher.RedemptionDate,
        RedeemerContactID:voucher.ContactID,
        Description: voucher.Description,
        ProgramStatus: voucher.IsActive === 1 ? "Yes" : "No",
        BrandName: voucher.BrandName,
        StartDate: voucher.StartDate,
        EndDate: voucher.EndDate,
        Points: voucher.Points,
        StatusPoints: voucher.StatusPoints,
        National: voucher.IsNational === 1 ? "Yes" : "No",
        Region: voucher.Region === "NULL" ? "" : voucher.Region,
        SalesOffice: voucher.SalesOffice === "NULL" ? "" : voucher.SalesOffice,
        Territory: voucher.Territory === "NULL" ? "" : voucher.Territory,
        DailyLimit: voucher.DailyLimit,
    }));

    return formattedList;
};

export const formItemLayout = {
    labelCol: {
        xs: {
            span: 10,
        },
        sm: {
            span: 7,
        },
        md: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 10,
        },
        sm: {
            span: 15,
        },
        md: {
            span: 10,
        },
    },
};



export var omnivoucherColumnCompare = [
    // "VoucherID",
    "VoucherCode",
    "ControlNumber",
    "Status",
    "Description",
    "IsActive",
    "BrandName",
    "StartDate",
    "EndDate",
    "Points",
    "StatusPoints",
    "IsNational",
    "Region",
    "SalesOffice",
    "Territory",
    "DailyLimit",
];
