import { SUCCESS_SUFFIX } from "redux-axios-middleware";
import HttpService from "../services/HttpService";

const LIST_TERRITORY = 'LIST_TERRITORY';
const ADD_TERRITORY = 'ADD_TERRITORY';
const EDIT_TERRITORY = 'EDIT_TERRITORY';
// const DELETE_TERRITORY = 'DELETE_TERRITORY';

const territoriesReducer = (state = [], action) => {
  switch (action.type) {
    case LIST_TERRITORY + SUCCESS_SUFFIX:
      return action.payload.data;
    // case DELETE_TERRITORY:
    //   return state.filter((territory) => territory.TerritoryName !== action.payload.territory.TerritoryName);

    default:
      return state;
  }
};

export default territoriesReducer;

export const allTerritories = () => ({
  type: LIST_TERRITORY,
  payload: {
    request: {
      url: '/api/territories',
    },
  },
});

export const addTerritory = territories => {
  return {
    type: ADD_TERRITORY,
    payload: {
      request: {
        url: '/api/territories',
        method: HttpService.HttpMethods.POST,
        data: territories,
      },
    },
  }
};


export const editTerritory = (id,territory) => {
  return {
    type: EDIT_TERRITORY,
    payload: {
      request: {
        url: `/api/territories/${id}`,
        method: HttpService.HttpMethods.PUT,
        data: territory
      },
    },
  }
};

// export const deleteTerritory = (ids) => {
//   return {
//     type: DELETE_TERRITORY,
//     payload: {
//       request: {
//         url: `/api/territories`,
//         method: HttpService.HttpMethods.DELETE,
//         data: ids
//       },
//     },
//   }
// };