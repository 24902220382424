import { SUCCESS_SUFFIX } from "redux-axios-middleware";
import HttpService from "../../services/HttpService";

const LIST_AUDIT_PROGRAM = 'LIST_AUDIT_PROGRAM';
const ADD_AUDIT_PROGRAM = 'ADD_AUDIT_PROGRAM';

function allOmnivoucherAuditLogs () {
  return {
    type: LIST_AUDIT_PROGRAM,
    payload: {
      request: {
        url: '/audit/omnivouchers',
        method: HttpService.HttpMethods.GET,
      },
    },
  }
};

function addOmnivoucherAuditLogs (audit) {
  return {
    type: ADD_AUDIT_PROGRAM,
    payload: {
      request: {
        url: '/audit/omnivouchers',
        method: HttpService.HttpMethods.POST,
        data: audit,
      },
    },
  }
};

const omnivoucherAuditLogsReducer = (state = [], action) => {

  // console.log('omnivoucherAuditLogsReducer()', state, ' ', action);

  switch (action.type) {
    case LIST_AUDIT_PROGRAM + SUCCESS_SUFFIX:
      return action.payload.data;

    default:
      return state;
  }
};

export {
  allOmnivoucherAuditLogs,
  addOmnivoucherAuditLogs,
};

export default omnivoucherAuditLogsReducer;
