import moment from "moment";
import _ from "lodash";

export var fieldNameOptions = [
    "Region",
    "Territory",
    "SalesOffice"
];

export var emptyFields = {
    Region: [],
    Territory: [],
    SalesOffice: []
};

export var omnivoucherProgramsColumns = [

    {
        title: "ID",
        width: 50,
        fixed: "left",
        dataIndex: "OmnivoucherID",
        key: "OmnivoucherID",
        defaultSortOrder: "ascend",
        sorter: (a, b) => a.OmnivoucherID - b.OmnivoucherID,
        align: "center",
    },
    {
        title: "PROGRAM DESCRIPTION",
        width: 120,
        fixed: "left",
        dataIndex: "Description",
        key: "Description",
        align: "center",
    },
    {
        title: "STATUS",
        width: 120,
        dataIndex: "IsActive",
        key: "IsActive",
        render: (value) => (value ? "Yes" : "No"),
        align: "center",
    },
    {
        title: "TELERIVET ENABLED",
        width: 120,
        dataIndex: "IsTelerivetEnabled",
        key: "IsTelerivetEnabled",
        render: (value) => (value ? "Yes" : "No"),
        align: "center",
    },
    {
        title: "BRAND NAME",
        width: 120,
        dataIndex: "BrandName",
        key: "BrandName",
        align: "center",
    },
    {
        title: "START DATE",
        width: 120,
        dataIndex: "StartDate",
        key: "StartDate",
        sorter: (a, b) => moment(a.StartDate).unix() - moment(b.StartDate).unix(),
        align: "center",
    },
    {
        title: "END DATE",
        width: 120,
        dataIndex: "EndDate",
        key: "EndDate",
        sorter: (a, b) => moment(a.EndDate).unix() - moment(b.EndDate).unix(),
        align: "center",
    },
    {
        title: "POINTS",
        width: 120,
        dataIndex: "Points",
        key: "Points",
        align: "center",
    },
    {
        title: "NATIONAL",
        width: 120,
        dataIndex: "IsNational",
        key: "IsNational",
        render: (value) => (value ? "Yes" : "No"),
        align: "center",
    },
    {
        title: "REGION",
        width: 120,
        dataIndex: "Region",
        key: "Region",
        render: (value) => (value === "NULL" ? "" : value),
    },
    {
        title: "SALES OFFICE",
        width: 120,
        dataIndex: "SalesOffice",
        key: "SalesOffice",
        render: (value) => (value === "NULL" ? "" : value),
    },
    {
        title: "TERRITORY",
        width: 120,
        dataIndex: "Territory",
        key: "Territory",
        render: (value) => (value === "NULL" ? "" : value),
    },

    {
        title: "DAILY LIMIT",
        width: 120,
        dataIndex: "DailyLimit",
        key: "DailyLimit",
        align: "center",
    },
];

export var omnivoucherAuditColumns = [
    {
        title: "TIMESTAMP",
        width: 50,
        dataIndex: "Timestamp",
        key: "Timestamp",
        render: (value) => moment(value).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
        title: "USER",
        width: 50,
        dataIndex: "User",
        key: "User",
        filterSearch: true,
    },
    {
        title: "ACTION",
        width: 50,
        dataIndex: "Action",
        key: "Action"
    },
    {
        title: "ID",
        width: 40,
        dataIndex: "OmnivoucherID",
        key: "OmnivoucherID"
    },
    {
        title: "OLD VALUE",
        width: 100,
        dataIndex: "OldValue",
        key: "OldValue",
        render: (value) => {
            if (value === null) {
                return "";
            }

            let val = JSON.parse(value);

            return Object.keys(val).map((e) => {
                return (
                    <p className="audit-para">
                        {e} : {val[ e ]}
                    </p>
                );
            });
        },
    },
    {
        title: "NEW VALUE",
        width: 100,
        dataIndex: "NewValue",
        key: "NewValue",
        render: (value) => {
            let val = JSON.parse(value);
            let obj = Object.keys(val).map((e) => {
                return (
                    <p className="audit-para">
                        {e} : {val[ e ]}
                    </p>
                );
            });
            return obj;
        },
    },
];

export var omnivoucherIntColumns = [
    "IsActive",
    "IsTelerivetEnabled",
    "Points",
    "IsNational",
    "DailyLimit",
];

export const filterSearchResult = (objs, values) => {

    // console.log("filterSearchResult()", values);

    let keys = Object.keys(values);

    let tempList = objs;

    _.forEach(keys, (key) => {
        if (
            key !== "StartDate" &&
            key !== "EndDate" &&
            key !== "IsNational" &&
            key !== "IsActive"
        ) {
            tempList = _.filter(tempList, function (o) {
                if (o[ key ] !== null && o[ key ].includes(values[ key ])) {
                    return o;
                }
            });
        }
        else if (
            key === "StartDate" ||
            key === "EndDate"
        ) {
            tempList = _.filter(tempList, function (o) {
                let timestamp = new Date(o.Timestamp);
                let start = new Date(values[ "StartDate" ]);
                let end = new Date(values[ "EndDate" ]);

                if (timestamp >= start && timestamp <= end)
                    return o;
            });
        }
        else if (
            key === "IsNational" ||
            key === "IsActive"
        ) {
            tempList = _.filter(tempList, function (o) {
                if (!!o[ key ] === values[ key ]) {
                    return o;
                }
            });
        }
    });

    return tempList;
};

export const fortmatExportList = (list, types) => {

    let formattedList = list.map((program) => ({
        ID: program.OmnivoucherID,
        Description: program.Description,
        Status: program.IsActive === 1 ? "Yes" : "No",
        BrandName: program.BrandName,
        StartDate: program.StartDate,
        EndDate: program.EndDate,
        // Points: program.Points,
        National: program.IsNational === 1 ? "Yes" : "No",
        Region: program.Region === "NULL" ? "" : program.Region,
        SalesOffice: program.SalesOffice === "NULL" ? "" : program.SalesOffice,
        Territory: program.Territory === "NULL" ? "" : program.Territory,
        DailyLimit: program.DailyLimit,
    }));

    return formattedList;
}

export const formItemLayout = {
    labelCol: {
        xs: {
            span: 10,
        },
        sm: {
            span: 7,
        },
        md: {
            span: 8,
        },
    },
    wrapperCol: {
        xs: {
            span: 10,
        },
        sm: {
            span: 15,
        },
        md: {
            span: 10,
        },
    },
};

export var omnivoucherColumnCompare = [
    "OmnivoucherID",
    "Description",
    "IsActive",
    "IsTelerivetEnabled",
    "BrandName",
    "StartDate",
    "EndDate",
    "Points",
    "IsNational",
    "Region",
    "SalesOffice",
    "Territory",
    "DailyLimit",
];
