import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allPrograms } from "../../modules/programs";
import { allProgramTypes } from "../../modules/programtypes";
import { allHurdles } from "../../modules/hurdles";
import { allTerritories } from "../../modules/territories";
import { allLuckyCodes } from "../../modules/luckycodes";

import {
  CaretRightOutlined,
  DownloadOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Table,
  Card,
  Col,
  Form,
  Row,
  Button,
  Select,
  Collapse,
  message,
} from "antd/lib";
import * as XLSX from "xlsx";
import { sort } from "../../helpers/helpers";
import {
  filterSearchResult,
  programColumns,
  fortmatExportList,
} from "../../helpers/program";
import ProgramModal from "./ProgramModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import "../../styles/Program.css";

const { Panel } = Collapse;
const { Option } = Select;

const ProgramList = () => {
  const dispatch = useDispatch();
  const { programs } = useSelector((state) => state);
  const { programtypes } = useSelector((state) => state);
  const { hurdles } = useSelector((state) => state);
  const { territories } = useSelector((state) => state);
  const { luckycodes } = useSelector((state) => state);

  const [program, setProgram] = useState();
  const [programList, setProgramList] = useState([]);
  const [visible, setVisible] = useState(false);
  const [action, setAction] = useState();

  useEffect(() => {
    dispatch(allPrograms());
    dispatch(allProgramTypes());
    dispatch(allTerritories());
    dispatch(allHurdles());
    dispatch(allLuckyCodes());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let result = programs.map((pr) => ({
      ...programtypes.find((prt) => pr.ProgramType === prt.ID),
      ...pr,
    }));
    setProgramList(result);
  }, [programs, programtypes]); // eslint-disable-line react-hooks/exhaustive-deps

  const operationColumn = {
    title: "",
    key: "operation",
    width: 40,
    fixed: "left",
    render: (text, record, index) => (
      <a
        onClick={(e) => {
          record.Date = [
            moment.utc(record.StartDate),
            moment.utc(record.EndDate),
          ];
          setProgram(record);
          openModal("Edit");
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </a>
    ),
  };

  const openModal = (action) => {
    showModal();
    setAction(action);
  };
  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFilterSubmit = (values) => {
    let filter = {};
    let keys = Object.keys(values);
    keys.map((key) => {
      if (values[key] !== undefined) {
        filter[key] = values[key];
      }
    });
    let searchResult = filterSearchResult(programs, filter);
    let result = searchResult.map((pr) => ({
      ...programtypes.find((prt) => pr.ProgramType === prt.ID),
      ...pr,
    }));
    setProgramList(result);
  };

  const onReset = (e) => {
    form.resetFields();
    setProgramList(programs);
  };

  const onDownload = (e) => {
    let exportList = fortmatExportList(programList, programtypes);
    const ws = XLSX.utils.json_to_sheet(exportList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Programs");
    XLSX.writeFile(wb, `Programs.xlsx`);
    if (programList.length !== programs.length)
      message.success("Filtered Programs downloaded!");
    else {
      message.success("All Programs downloaded!");
    }
  };

  const [form] = Form.useForm();
  return (
    <div className="row">
      <Card title="PROGRAM LIST">
        <Button
          type="danger"
          style={{ width: "10em", float: "right", marginLeft: "20px" }}
          onClick={onDownload}
          icon={<DownloadOutlined />}
        >
          Download
        </Button>
        <Button
          className="btnHurdle"
          type="danger"
          icon={<PlusOutlined />}
          style={{
            width: "10em",
            float: "right",
            marginRight: "6px",
            marginBottom: "20px",
          }}
          onClick={(e) => openModal("Add")}
        >
          Add
        </Button>
        <br />
        <br />
        <Collapse
          bordered={false}
          ghost
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
        >
          <Panel header="FILTER" key="1">
            <Form
              form={form}
              name="formAuditFilter"
              onFinish={onFilterSubmit}
              labelAlign="right"
            >
              <Row>
                <Col>
                  <Form.Item
                    name="BrandName"
                    label="Brand Name"
                    style={{ marginLeft: "2em" }}
                  >
                    <Select allowClear style={{ width: "10em" }}>
                      {programList
                        ? sort([
                            ...new Set(programs.map((x) => x.BrandName)),
                          ]).map((value) => {
                            return (
                              <Option value={value} key={value} label={value}>
                                {value}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="SKU"
                    label="SKU"
                    style={{ marginLeft: "2em" }}
                  >
                    <Select
                      // mode="multiple"
                      allowClear
                      style={{ width: "10em" }}
                    >
                      {programList
                        ? sort([...new Set(programs.map((x) => x.SKU))]).map(
                            (value) => {
                              return (
                                <Option value={value} key={value} label={value}>
                                  {value}
                                </Option>
                              );
                            }
                          )
                        : null}
                    </Select>
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item
                    name="IsNational"
                    label="National"
                    style={{ marginLeft: "2em" }}
                  >
                    <Select allowClear style={{ width: "7em" }}>
                      <Option value={false} key={false} label="No">
                        No
                      </Option>
                      <Option value={true} key={true} label="Yes">
                        Yes
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="IsProgramActive"
                    label="Status"
                    style={{ marginLeft: "2em" }}
                  >
                    <Select allowClear style={{ width: "7em" }}>
                      <Option value={false} key={false} label="No">
                        No
                      </Option>
                      <Option value={true} key={true} label="Yes">
                        Yes
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    name="ProgramType"
                    label="Type"
                    style={{ marginLeft: "2em" }}
                  >
                    <Select
                      // mode="multiple"
                      allowClear
                      style={{ width: "10em" }}
                    >
                      {programtypes
                        ? programtypes.map((obj) => {
                            return (
                              <Option
                                value={obj.ID}
                                key={obj.ID}
                                label={obj.ID}
                              >
                                {obj.Type}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Button
                  type="danger"
                  htmlType="submit"
                  style={{ width: "10em", float: "right", marginLeft: "20px" }}
                >
                  Filter
                </Button>
                <Button
                  type="danger"
                  style={{ width: "10em", float: "right", marginLeft: "20px" }}
                  onClick={onReset}
                >
                  Reset Filter
                </Button>
              </Row>
            </Form>
          </Panel>
        </Collapse>

        {programList.length > 0 ? (
          <Table
            className="program-table"
            rowClassName="row-programlist"
            rowKey={(program) => program.ProgramID}
            columns={[operationColumn, ...programColumns]}
            dataSource={programList}
            scroll={{ x: "max-content" }}
            pagination={{ pageSize: 15 }}
          />
        ) : (
          <Table
            className="program-table"
            rowClassName="row-programlist"
            columns={[operationColumn, ...programColumns]}
            scroll={{ x: "max-content" }}
            pagination={{ pageSize: 15 }}
          />
        )}

        <ProgramModal
          program={action === "Edit" ? program : null}
          show={showModal}
          programs={programList}
          programtypes={programtypes}
          territories={territories}
          luckycodes={luckycodes}
          hurdles={hurdles}
          onCancel={handleCancel}
          columns={[operationColumn, ...programColumns]}
          action={action}
          visible={visible}
          className="modal-hurdle"
        />
      </Card>
    </div>
  );
};

export default ProgramList;
