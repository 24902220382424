import { message } from "antd/lib";

export const sendSuccessMessage = (type, action) => {
  message.success({
    content: `${type} ${action.toLowerCase()}ed successfully!`,
    duration: 5,
  });
};

export const sendFailedMessage = (type, action) => {
  message.error({
    content: `Cannot ${action.toLowerCase()} ${type.toLowerCase()}! Please try again later or contact support.`,
    duration: 5,
  });
};

export const sendFailedCustomMessage = (type, action, customMessage) => {
  message.error({
    content: `Cannot ${action.toLowerCase()} ${type.toLowerCase()}! ${customMessage}`,
    duration: 10,
  });
};

export const sendFailedRequiredMessage = (type, action) => {
  message.error({
    content: `Cannot ${action.toLowerCase()} ${type.toLowerCase()}! Please set Region, Sales Office or Territory`,
    duration: 5,
  });
};

// export const sendFailedRequiredContactFlexMessage = (type, action) => {
//   message.error({
//     content: `Cannot ${action.toLowerCase()} ${type.toLowerCase()}! Please set Contact Flex 2 or 3`,
//     duration: 5,
//   });
// };

export const sendExistMessage = (type, action) => {
  message.error({
    content: `Cannot ${action.toLowerCase()} ${type.toLowerCase()}! Either no changes performed or ${type} already exists.`,
    duration: 5,
  });
};

export const sendWarningNoChanges = (type, action) => {
  message.error({
    content: `No changes performed!`,
    duration: 5,
  });
};
