import { Link } from "react-router-dom";
import { Menu, Divider } from "antd/lib";
import UserService from "../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const SideMenu = () => {
  return (
    <Menu mode="vertical" className="side-menu">
      <div className="menu-user">
        Signed in as <span>{UserService.getUsername()}</span>
      </div>
      <Menu.ItemGroup title="PROGRAM">
        <Menu.Item key="1">
          <Link to="/programs">List</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/add-program">Add Program</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/audit-program">Audit</Link>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.Item key="territory">
        <Link to="/territories">TERRITORY</Link>
      </Menu.Item>
      {/* <Menu.ItemGroup title={<span>GAME</span>}>
        <Menu.Item key="1">
          <Link to="/games">Game</Link>
        </Menu.Item>
        <Menu.Item key="2">
          <Link to="/gametypes">Game Type</Link>
        </Menu.Item>
      </Menu.ItemGroup> */}
      <Menu.Item key="hurdle">
        <Link to="/hurdles">HURDLE</Link>
      </Menu.Item>

      {UserService.hasRole(["code-generator-admin"]) ? (
        <Menu.Item key="code-randomizer">
          <Link to="/code-randomizer">CODE RANDOMIZER</Link>
        </Menu.Item>
      ) : null}

      {UserService.hasRole(["omnivoucher-admin"]) ? (
        <Menu.ItemGroup title="OMNIVOUCHER">
          <Menu.Item key="1">
            <Link to="/omnivouchers">Voucher List</Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/omnivoucher-programs">Program List</Link>
          </Menu.Item>
        </Menu.ItemGroup>
      ) : null}

      <Divider />

      <Menu.Item key="logout" onClick={() => UserService.doLogout()}>
        Sign out <FontAwesomeIcon icon={faSignOutAlt} />
      </Menu.Item>
      {/* <Menu.ItemGroup title="Territories">
        <Menu.Item key="3">
          <Link to="/territories">List</Link>
        </Menu.Item>
        <Menu.Item key="3">
          <Link to="/territories">Update Territories</Link>
        </Menu.Item>
      </Menu.ItemGroup> */}
    </Menu>
  );
};
export default SideMenu;
