import { Menu } from "antd/lib";
import UserService from "../../services/UserService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

const SubMenu = Menu.SubMenu;

const RightMenu = () => {
  return (
    <div className="right-menu-container">
      <Menu mode="horizontal" className="right-menu">
        <SubMenu
          key="vertical-submenu"
          title={
            <span>
              {UserService.getUsername()}{" "}
              <FontAwesomeIcon icon={faAngleDown}></FontAwesomeIcon>
            </span>
          }
        >
          <Menu.Item key="logout" onClick={() => UserService.doLogout()}>
            Sign out <FontAwesomeIcon icon={faSignOutAlt} />
          </Menu.Item>
        </SubMenu>
      </Menu>
    </div>
  );
};
export default RightMenu;
