import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
    fnGetAllOmnivoucherPrograms,
    fnAddOmnivoucherProgram,
    fnUpdateOmnivoucherProgram
} from "../../modules/omnivoucher/omnivoucherPrograms";

import UserService from "../../services/UserService";

import {
    Input,
    Select,
    Button,
    Switch,
    Modal,
    Form,
    DatePicker,
    Divider,
    Checkbox,
    // Descriptions,
} from "antd/lib";

import '../../styles/Program.css';

import {
    requiredTypes,
    sort,
    filterByPropertyValue,
    compare
} from "../../helpers/helpers";

import {
    formItemLayout,
    fieldNameOptions,
    omnivoucherColumnCompare,
    omnivoucherIntColumns,
    emptyFields
} from "../../helpers/omnivoucherPrograms";

import {
    sendSuccessMessage,
    sendFailedMessage,
    sendExistMessage,
    sendFailedRequiredMessage,
    sendFailedCustomMessage,
} from "../../helpers/messageHelper";

import { addOmnivoucherProgramAuditLogs } from "../../modules/omnivoucher/omnivoucherProgramsAudit";
import { toUpper } from "lodash";

const { Option } = Select;
const { RangePicker } = DatePicker;

const ProgramModal = (props) => {

    const dispatch = useDispatch();

    const { territories } = useSelector((state) => state);

    const [form] = Form.useForm();

    const [salesOfficeList, setSalesOfficeList] = useState();
    const [regionList, setRegionList] = useState();
    const [territoryList, setTerritoryList] = useState();

    const [submitting, setSubmitting] = useState(false);
    const [disabled, setDisabled] = useState({});

    useEffect(() => {
        setRegionList(sort([...new Set(territories.map((x) => x.Region))]));
        setSalesOfficeList(sort([...new Set(territories.map((x) => x.SalesOffice))]));
        setTerritoryList(sort([...new Set(territories.map((x) => x.TerritoryName))]));
    }, [territories]);


    useEffect(() => {
        if (props.visible && props.action === "Edit" && props.omnivoucherProgram) {

            manageOptions("Region", props.omnivoucherProgram.Region, true);
            manageOptions("SalesOffice", props.omnivoucherProgram.SalesOffice, true);
            manageOptions("Territory", props.omnivoucherProgram.Territory, true);

            let prog = {};
            if (props.omnivoucherProgram.IsNational) {
                setDisabled(true);
                prog = {
                    ...props.omnivoucherProgram,
                    ...emptyFields,
                };
            }
            else {
                setDisabled(false);
                prog = {
                    ...props.omnivoucherProgram,
                    Region: props.omnivoucherProgram.Region === "NULL" ? [] : props.omnivoucherProgram.Region.split(","),
                    Territory: props.omnivoucherProgram.Territory === "NULL" ? [] : props.omnivoucherProgram.Territory.split(","),
                    SalesOffice: props.omnivoucherProgram.SalesOffice === "NULL" ? [] : props.omnivoucherProgram.SalesOffice.split(",")
                };
            }

            form.setFieldsValue(prog);
        }
        else {
            form.resetFields();
        }
    }, [props.omnivoucherProgram]);

    useEffect(() => {
        if (props.action === "Add") setDisabled(false);
    }, [props.action]);

    const manageOptions = (key, value, isInitial) => {

        setRegionList(sort([...new Set(territories.map((x) => x.Region))]));

        if (value !== null && value.length > 0) {

            let filteredList = filterByPropertyValue(territories, key, value);

            switch (key) {
                case "Region": setSalesOfficeList(sort([...new Set(filteredList.map((x) => x.SalesOffice))]));
                    setTerritoryList(sort([...new Set(filteredList.map((x) => x.TerritoryName))]));
                    if (!isInitial) form.setFieldsValue({ Territory: [], SalesOffice: [] });
                    break;
                case "SalesOffice": setTerritoryList(sort([...new Set(filteredList.map((x) => x.TerritoryName))]));
                    if (!isInitial) form.setFieldsValue({ Territory: [] });
                    break;
                default:
                    break;
            }

        }
    };

    const doesProgramExist = (values) => {
        let exists = false;
        for (let index = 0; index <= props.omnivoucherPrograms.length; index++) {
            exists = compare(props.omnivoucherPrograms[index], values, omnivoucherColumnCompare);
            if (exists) break;
        }
        return exists;
    };

    const callAdd = (values, audit) => {

        dispatch(fnAddOmnivoucherProgram(values))
            .then((response) => {

                if (Object.hasOwnProperty.call(response, 'error')) {

                    if (
                        Object.hasOwnProperty.call(response.error, 'response') &&
                        Object.hasOwnProperty.call(response.error.response, 'data') &&
                        Object.hasOwnProperty.call(response.error.response.data, 'message')
                    ) {
                        sendFailedCustomMessage(
                            "Program",
                            "Add",
                            response.error.response.data.message
                        );

                        return;

                    } else {

                        throw response.error;
                    }
                }

                let data = response.payload.data;

                if (data.success) {

                    audit = {
                        ...audit,
                        NewValue: JSON.stringify(values),
                        OmnivoucherID: data.id
                    };

                    dispatch(addOmnivoucherProgramAuditLogs(audit));
                    dispatch(fnGetAllOmnivoucherPrograms());

                    props.onCancel();
                    form.resetFields();
                    setDisabled(false);

                    sendSuccessMessage("Program", "Add");

                } else {

                    sendFailedMessage("Program", "Add");
                }
            })
            .catch((error) => {

                console.log(error);

                sendFailedMessage("Program", "Add");
            });
    };

    const callUpdate = (values, audit) => {
        var newValues = {},
            oldValues = {};

        Object.keys(props.omnivoucherProgram).map(function (key) {
            if (omnivoucherIntColumns.some((text) => text === key)) {
                if (parseInt(props.omnivoucherProgram[key]) !== parseInt(values[key])) {
                    oldValues[key] = props.omnivoucherProgram[key];
                    newValues[key] = values[key];
                }
            } else {
                if (props.omnivoucherProgram[key] !== values[key] && key !== "OmnivoucherID") {
                    oldValues[key] = props.omnivoucherProgram[key];
                    newValues[key] = values[key];
                }
            }
        });
        if (
            Object.keys(oldValues).length > 0 &&
            Object.keys(newValues).length > 0
        ) {
            var audit = {
                ...audit,
                OldValue: JSON.stringify(oldValues),
                NewValue: JSON.stringify(newValues),
            };

            dispatch(fnUpdateOmnivoucherProgram(props.omnivoucherProgram.OmnivoucherID, values))
                .then((response) => {

                    if (Object.hasOwnProperty.call(response, 'error')) {

                        if (
                            Object.hasOwnProperty.call(response.error, 'response') &&
                            Object.hasOwnProperty.call(response.error.response, 'data') &&
                            Object.hasOwnProperty.call(response.error.response.data, 'message')
                        ) {
                            sendFailedCustomMessage(
                                "Program",
                                "Edit",
                                response.error.response.data.message
                            );

                            return;

                        } else {

                            throw response.error;
                        }
                    }

                    if (response.payload.data.success) {
                        sendSuccessMessage("Program", "Edit");
                        dispatch(addOmnivoucherProgramAuditLogs(audit));
                        dispatch(fnGetAllOmnivoucherPrograms());
                    } else {
                        sendFailedMessage("Program", "Edit");
                    }
                })
                .catch((e) => {
                    sendFailedMessage("Program", "Edit");
                    console.log(e);
                });
        } else {
            sendExistMessage("Program", "Edit");
        }
    };

    const onFinish = (e) => {
        setSubmitting(true);

        let valid = true;

        if (!e.IsNational && e.Region.length === 0 && e.SalesOffice.length === 0 && e.Territory.length === 0) {
            sendFailedRequiredMessage("Program", props.action);
            valid = false;
        }

        if (valid) {

            e = {
                ...e,
                Points: parseInt(e.Points),
                StatusPoints: parseInt(e.StatusPoints),

                StartDate: e.Date[0].format("YYYY-MM-DD") + " 00:00:00",
                EndDate: e.Date[1].format("YYYY-MM-DD") + " 23:59:59",
                //
                Region: e.Region.toString() !== "" ? e.Region.toString() : "NULL",
                SalesOffice: e.SalesOffice.toString() !== "" ? e.SalesOffice.toString() : "NULL",
                Territory: e.Territory.toString() !== "" ? e.Territory.toString() : "NULL",

                DailyLimit: parseInt(e.DailyLimit),
            };

            delete e.Date;

            if (doesProgramExist(e)) {
                sendExistMessage("Program", props.action);
            } else {
                var audit = {
                    User: UserService.getUsername(),
                    Action: toUpper(props.action),
                    OmnivoucherID: props.action === "Edit" ? props.omnivoucherProgram.OmnivoucherID : null,
                };

                if (props.action === "Edit")
                    callUpdate(e, audit);
                else
                    callAdd(e, audit);
            }
        }

        setSubmitting(false);
    };

    const onDataChange = (changedValues) => {
        const fieldName = Object.keys(changedValues)[0];
        const value = Object.values(changedValues)[0];
        switch (fieldName) {
            case "IsNational":
                if (value === true) {
                    setDisabled(true);
                    form.setFieldsValue({ Region: [], Territory: [], SalesOffice: [] });
                }
                else setDisabled(false);
                break;
        }

        if (fieldNameOptions.includes(fieldName)) {
            manageOptions(fieldName, value, false);
        }
    };
    return (
        <Modal
            title={props.action === "Edit" ? `${props.action} Program ID: ${props.omnivoucherProgram.OmnivoucherID}` : "Add New Program"}
            visible={props.visible}
            footer={null}
            onCancel={props.onCancel}
            width={800}
            style={{ height: 'calc(100vh - 120px)' }}
            bodyStyle={{ overflowY: 'scroll' }}
        >

            <Form preserve={false}
                id="programForm"
                hideRequiredMark
                name="form"
                form={form}
                onFinish={onFinish}
                {...formItemLayout}
                onValuesChange={onDataChange}
                labelAlign="right"
            >
                <Form.Item hidden name="OmnivoucherID" label="OmnivoucherID">
                    <Input placeholder="ID" />
                </Form.Item>
                <Divider>Omnivoucher Details</Divider>
                <Form.Item name="Description" label="Program Description" rules={requiredTypes.default}>
                    <Input placeholder="Enter UNIQUE Program Description of the Omnivoucher Program" />
                </Form.Item>
                <Form.Item name="BrandName" label="Brand Name" rules={requiredTypes.default}>
                    <Input placeholder="Enter Brand Name to be displayed on the site" />
                </Form.Item>
                {/* <Form.Item name="BrandCodes" label="Brand Codes" rules={requiredTypes.default}>
                    <Input placeholder="Enter BrandCode/s information from Promosys (comma-delimited)" />
                </Form.Item> */}

                <Divider>Telerivet Interaction</Divider>
                <Form.Item name="IsTelerivetEnabled" label="Enabled" valuePropName="checked">
                    <Switch />
                </Form.Item>

                <Divider>Status &amp; Schedule</Divider>
                <Form.Item name="IsActive" label="Enabled" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item name="Date" label="Date" rules={requiredTypes.default}>
                    <RangePicker
                        style={{ width: "100%" }}
                        disabledDate={(current) => {
                            return current && current < moment().startOf("day");
                        }}
                    />
                </Form.Item>
                <Form.Item name="Points" label="Points" rules={requiredTypes.default}>
                    <Input
                        type="number"
                        // maxLength="2"
                        placeholder="Enter desired points for the program"
                        // onInput={(e) => {
                        //     e.target.value = Math.max(0, parseInt(e.target.value))
                        //         .toString()
                        //         .slice(0, 2);
                        // }}
                        onKeyDown={(evt) =>
                            (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                            evt.preventDefault()
                        }
                    />
                </Form.Item>
                <Form.Item name="StatusPoints" label="Status Points" rules={requiredTypes.default}>
                    <Input
                        type="number"
                        maxLength="2"
                        placeholder="Enter desired points for the program"
                        onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 2);
                        }}
                        onKeyDown={(evt) =>
                            (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                            evt.preventDefault()
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="DailyLimit"
                    label="Daily Limit"
                    rules={requiredTypes.default}
                >
                    <Input
                        type="number"
                        onInput={(e) => {
                            e.target.value = Math.max(0, parseInt(e.target.value))
                                .toString()
                                .slice(0, 2);
                        }}
                        placeholder="Enter the number of daily limit"
                        onKeyDown={(evt) =>
                            (evt.key === "e" || evt.key === "+" || evt.key === "-") &&
                            evt.preventDefault()
                        }
                    />
                </Form.Item>

                <Divider>Target</Divider>
                <Form.Item name="IsNational" valuePropName="checked" label="Is National">
                    <Checkbox></Checkbox>
                </Form.Item>
                <Form.Item name="Region" label="Region" rules={!disabled ? requiredTypes.default : ''}>
                    <Select
                        mode="multiple"
                        placeholder="Separated by comma. Leave BLANK if none"
                        allowClear
                        optionLabelProp="label"
                        disabled={disabled}
                    >
                        {regionList
                            ? regionList.map((region) => {
                                return (
                                    <Option value={region} key={region} label={region}>
                                        {region}
                                    </Option>
                                );
                            })
                            : null}
                    </Select>
                </Form.Item>

                <Form.Item name="SalesOffice" label="Sales Office">
                    <Select
                        mode="multiple"
                        placeholder="Separated by comma. Leave BLANK if none"
                        allowClear
                        disabled={disabled}
                    >
                        {salesOfficeList
                            ? salesOfficeList.map((value) => {
                                return (
                                    <Option value={value} key={value} label={value}>
                                        {value}
                                    </Option>
                                );
                            })
                            : null}
                    </Select>
                </Form.Item>
                <Form.Item name="Territory" label="Territory">
                    <Select
                        mode="multiple"
                        placeholder="Separated by comma. Leave BLANK if none"
                        allowClear
                        optionLabelProp="label"
                        disabled={disabled}
                    >
                        {territoryList
                            ? territoryList.map((territory) => {
                                return (
                                    <Option
                                        value={territory}
                                        key={territory}
                                        label={territory}
                                    >
                                        {territory}
                                    </Option>
                                );
                            })
                            : null}
                    </Select>
                </Form.Item>

                <div>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="submit-button"
                        disabled={submitting}
                    >
                        SUBMIT
                    </Button>
                </div>
            </Form>

        </Modal >
    );
};
export default ProgramModal;
