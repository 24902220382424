import { SUCCESS_SUFFIX } from "redux-axios-middleware";
import HttpService from "../services/HttpService";

const LIST_RANDOMIZER_BATCH_CODE = "LIST_RANDOMIZER_BATCH_CODE";
const CODES_BY_BATCH_ID = "CODES_BY_BATCH_ID";
const BATCH_CODE_INFO = "BATCH_CODE_INFO";
const GENERATE_CODES = "GENERATE_CODES";
const CREATE_DOWNLOAD_LOG = "CREATE_DOWNLOAD_LOG";
const DOWNLOAD_LOG_BY_BATCH_ID = "DOWNLOAD_LOG_BY_BATCH_ID";

export const batchCodesReducer = (state = [], action) => {
  switch (action.type) {
    case LIST_RANDOMIZER_BATCH_CODE + SUCCESS_SUFFIX:
      return action.payload.data;
    case BATCH_CODE_INFO + SUCCESS_SUFFIX:
      if (action.payload.data)
        return [
          ...state.filter((x) => x.ID !== action.payload.data.ID),
          action.payload.data,
        ];
      break;
    default:
      return state;
  }
};

// export const codesByBatchIdReducer = (state = [], action) => {
//   switch (action.type) {
//     case CODES_BY_BATCH_ID + SUCCESS_SUFFIX:
//       return action.payload.data;

//     default:
//       return state;
//   }
// };

export const downloadLogsByBatchIdReducer = (state = [], action) => {
  switch (action.type) {
    case DOWNLOAD_LOG_BY_BATCH_ID + SUCCESS_SUFFIX:
      return action.payload.data;

    default:
      return state;
  }
};

export const allRandomizerBatchCodes = () => ({
  type: LIST_RANDOMIZER_BATCH_CODE,
  payload: {
    request: {
      url: "/cms/code-randomizer",
    },
  },
});

export const allCodesByBatchId = (id) => ({
  type: CODES_BY_BATCH_ID,
  payload: {
    request: {
      url: `/cms/code-randomizer/${id}`,
    },
  },
});

export const getBatchCodeInfo = (id) => ({
  type: BATCH_CODE_INFO,
  payload: {
    request: {
      url: `/cms/code-randomizer/batchcode/${id}`,
    },
  },
});

export const generateCodes = (prefix, count, length, createdBy) => ({
  type: GENERATE_CODES,
  payload: {
    request: {
      url: "/cms/code-randomizer",
      method: HttpService.HttpMethods.POST,
      data: { prefix, count, length, createdBy },
    },
  },
});

export const createdDownloadLog = (batchId, user) => ({
  type: CREATE_DOWNLOAD_LOG,
  payload: {
    request: {
      url: "/cms/code-randomizer/download-log",
      method: HttpService.HttpMethods.POST,
      data: { batchId, user },
    },
  },
});

export const allDownloadLogsByBatchId = (id) => ({
  type: DOWNLOAD_LOG_BY_BATCH_ID,
  payload: {
    request: {
      url: `/cms/code-randomizer/download-log/${id}`,
    },
  },
});
