import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Card,
  Button,
  Row,
  Col,
  Tooltip,
  message,
  Divider,
  Progress,
  Modal,
} from "antd/lib";
import UserService from "../../services/UserService";
import {
  allRandomizerBatchCodes,
  allCodesByBatchId,
  getBatchCodeInfo,
  createdDownloadLog,
  allDownloadLogsByBatchId,
} from "../../modules/codeRandomizer";
import {
  DownloadOutlined,
  PlusOutlined,
  HistoryOutlined,
  UserOutlined,
} from "@ant-design/icons";
import CodeRandomizerModal from "./CodeRandomizerModal";
import DownloadHistoryModal from "./DownloadHistoryModal";
import moment from "moment";
const fileDownload = require("js-file-download");

const CodeRandomizer = () => {
  const codeRandomizerColumns = [
    {
      title: "ID",
      width: 50,
      dataIndex: "ID",
      key: "ID",
      name: "ID",
      maxLength: 3,
    },
    {
      title: "BATCH DATE",
      width: 50,
      dataIndex: "BatchDate",
      key: "BatchDate",
      name: "Batch Date",
      render: (value) => moment.utc(value).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "PREFIX",
      width: 50,
      dataIndex: "Prefix",
      key: "Prefix",
      name: "Prefix",
    },
    {
      title: "LENGTH",
      width: 50,
      dataIndex: "Length",
      key: "Length",
      name: "Length",
    },
    {
      title: "COUNT",
      width: 50,
      dataIndex: "Count",
      key: "Count",
      name: "Count",
    },
    {
      title: "COMPLETED",
      width: 50,
      dataIndex: "IsCompleted",
      key: "IsCompleted",
      name: "IsCompleted",
      render: (text, record, index) => (record.IsCompleted ? "Yes" : "No"),
    },
    {
      title: "CREATED BY",
      width: 50,
      dataIndex: "CreatedBy",
      key: "CreatedBy",
      name: "CreatedBy",
    },
    {
      title: "DOWNLOAD HISTORY",
      width: 50,
      dataIndex: "RecentDownload",
      key: "RecentDownload",
      name: "RecentDownload",
      render: (text, record, index) => (
        <>
          <HistoryOutlined
            style={{ cursor: "pointer", color: "#3498db" }}
            onClick={() => downloadLog(record)}
          />
        </>
      ),
    },
    {
      //   title: "DOWNLOAD",
      key: "download",
      width: 30,
      render: (text, record, index) => (
        <>
          <Tooltip
            placement="top"
            title={`Download Batch ${record.ID} CSV File`}
          >
            {record.IsCompleted ? (
              <DownloadOutlined
                onClick={() => downloadCodesbyId(record)}
                style={
                  isDownloadClicked
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer", color: "#3498db" }
                }
                disabled={isDownloadClicked}
              />
            ) : (
              <DownloadOutlined style={{ cursor: "not-allowed" }} />
            )}
          </Tooltip>
        </>
      ),
    },
  ];

  const dispatch = useDispatch();

  const {
    batchCodes,
    // codesByBatchId,
    downloadLogsByBatchId,
  } = useSelector((state) => state);

  const [batchCodesList, setBatchCodesList] = useState();
  // const [batch, setBatch] = useState();
  const [isDownloadClicked, setIsDownloadClicked] = useState(false);

  const [showAddModal, setShowAddModal] = useState(false);
  const [disableGenerate, setDisableGenerate] = useState(false);
  const [batchCodeInProgress, setBatchCodeInProgress] = useState();

  const [showDownloadHistoryModal, setShowDownloadHistoryModal] =
    useState(false);

  // const [codesByBatchIdList, setCodesByBatchIdList] = useState();

  // const [downloadHistoryByBatch, setDownloadHistoryByBatch] = useState();
  // const [downloadHistoryList, setDownloadHistoryList] = useState();

  useEffect(() => {
    dispatch(allRandomizerBatchCodes());
  }, []);

  useEffect(() => {
    setBatchCodesList(batchCodes);
    let batchcode = batchCodes.find((x) => !x.IsCompleted);
    if (batchcode) {
      setDisableGenerate(true);
      setBatchCodeInProgress(batchcode);
    } else {
      setDisableGenerate(false);
      setBatchCodeInProgress(null);
    }
  }, [batchCodes]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (disableGenerate) dispatch(getBatchCodeInfo(batchCodeInProgress.ID));
    }, 1 * 60 * 1000);
    return () => clearTimeout(timer);
  });

  const batchExists = (prefix) => {
    let batch = batchCodesList.find((x) => x.Prefix === prefix);
    return batch ? true : false;
  };

  const downloadCodesbyId = (batch) => {
    setIsDownloadClicked(true);
    // setBatch(batch);

    dispatch(allCodesByBatchId(batch.ID))
      .then((response) => {
        if (response.payload.status === 200) {
          const codes = response.payload.data;
          fileDownload(
            codes,
            `Random_Code_Generated_${batch.Prefix}_${moment(
              batch.BatchDate
            ).format("YYYYMMDD")}.csv`
          );

          message.success(`Batch ${batch.ID} Downloaded`, 5);
          setIsDownloadClicked(false);
        } else {
          message.error("Something went wrong downloading csv file");
        }
      })
      .catch((e) => {
        message.error("Error on downloading csv file");
      });

    dispatch(createdDownloadLog(batch.ID, UserService.getName()));
  };

  const downloadLog = (batch) => {
    // setBatch(batch);
    dispatch(allDownloadLogsByBatchId(batch.ID));
    openDownloadHistoryModal();
  };

  const openAddModal = () => {
    setShowAddModal(true);
  };

  const handleCancelAddModal = () => {
    setShowAddModal(false);
  };

  const openDownloadHistoryModal = () => {
    setShowDownloadHistoryModal(true);
  };

  const handleCancelDownloadHistoryModal = () => {
    setShowDownloadHistoryModal(false);
  };

  return (
    <div className="row">
      <Card title="CODE RANDOMIZER">
        <Row>
          <Col flex={1}>
            <Button
              className="btnGenerateNew"
              disabled={disableGenerate}
              type="danger"
              icon={<PlusOutlined />}
              style={{
                width: "11em",
                float: "right",
                marginRight: "6px",
                marginBottom: "20px",
              }}
              onClick={(e) => openAddModal()}
            >
              ADD
            </Button>
          </Col>
        </Row>
        <Table
          className="code-randomizer-table"
          rowClassName="code-randomizer-rows"
          rowKey={(code) => code.ID}
          columns={codeRandomizerColumns}
          dataSource={batchCodesList}
        />
        <CodeRandomizerModal
          show={openAddModal}
          onCancel={handleCancelAddModal}
          visible={showAddModal}
          disableGenerate={disableGenerate}
          batchExists={batchExists}
          // style={{ height: "calc(100vh - 200px)" }}
          // bodyStyle={{ overflowY: "scroll" }}
        />
        <DownloadHistoryModal
          show={openDownloadHistoryModal}
          onCancel={handleCancelDownloadHistoryModal}
          visible={showDownloadHistoryModal}
          downloadHistoryList={downloadLogsByBatchId}
          // disableGenerate={disableGenerate}
          // batchExists={batchExists}
          // style={{ height: "calc(100vh - 200px)" }}
          // bodyStyle={{ overflowY: "scroll" }}
        />
      </Card>
    </div>
  );
};

export default CodeRandomizer;
